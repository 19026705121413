import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../layouts/ThemeContext";

const PriceFilter = ({ filter, setFilter, sets }) => {
  const { theme } = useContext(ThemeContext);
  const [localFilter, setLocalFilter] = useState(filter);

  useEffect(() => {
    setLocalFilter(filter);
  }, [filter]);

  useEffect(() => {
    setFilter(localFilter);
  }, [localFilter, setFilter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const inputClasses = `mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
    theme === "dark"
      ? "bg-gray-800 border-gray-700 text-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
      : "bg-white border-gray-300 text-gray-900 focus:border-indigo-500 focus:ring-indigo-500"
  }`;

  const labelClasses = `block text-sm font-medium ${
    theme === "dark" ? "text-gray-300" : "text-gray-700"
  }`;

  return (
    <div className="filter-component space-y-4">
      <div>
        <label htmlFor="set_code" className={labelClasses}>
          Set Name
        </label>
        <select
          name="set_code"
          id="set_code"
          value={localFilter.set_code}
          onChange={handleChange}
          className={inputClasses}
        >
          <option value="all">All</option>
          {sets.map((set) => (
            <option key={set.code} value={set.code}>
              {set.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="source" className={labelClasses}>
          Price Source
        </label>
        <select
          name="source"
          id="source"
          value={localFilter.source}
          onChange={handleChange}
          className={inputClasses}
        >
          <option value="">All</option>
          <option value="Normal">Normal</option>
          <option value="Foil">Foil</option>
          <option value="Etched">Etched</option>
        </select>
      </div>
    </div>
  );
};

export default PriceFilter;
