import React, { useState, useContext, useEffect, useRef } from "react";
import Card from "../misc/Card";
import { ThemeContext } from "../layouts/ThemeContext";
import Button from "../forms/Button"; // Assuming this is your custom Button component

const SortableTable = ({ data, columns, title, enableColumnSelection = false }) => {
  const { theme } = useContext(ThemeContext);
  const titleSize = "text-2xl";
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [automatic, setAutomatic] = useState(true); // New state for automatic mode
  const tableRef = useRef(null);

  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const toggleColumnVisibility = (columnKey) => {
    if (automatic) return;
    setVisibleColumns((prevVisibleColumns) =>
      prevVisibleColumns.some((col) => col.key === columnKey)
        ? prevVisibleColumns.filter((col) => col.key !== columnKey)
        : [...prevVisibleColumns, columns.find((col) => col.key === columnKey)]
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (!automatic) return;
      const availableWidth = tableRef.current.offsetWidth;
      let totalWidth = 0;
      const newVisibleColumns = [];

      columns.forEach((col) => {
        const width = col.width || 150; // Assume default width if not provided
        if (totalWidth + width <= availableWidth) {
          totalWidth += width;
          newVisibleColumns.push(col);
        }
      });

      setVisibleColumns(newVisibleColumns);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [columns, automatic]);

  const handleMouseDown = (e, colIndex) => {
    if (automatic) return;

    const startX = e.pageX;
    const startWidth = e.target.parentElement.offsetWidth;

    const handleMouseMove = (e) => {
      const newWidth = startWidth + (e.pageX - startX);
      if (newWidth > 50) {
        const newColumns = [...visibleColumns];
        newColumns[colIndex].width = newWidth;
        setVisibleColumns(newColumns);
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <Card title={title} className={'w-full'} titleSize={titleSize}>
      {enableColumnSelection && (
        <div className="flex justify-between items-center mb-4">
          <Button
            className="relative"
            customDropdownContent={
              <div className={`py-1 ${theme === "dark" ? "bg-gray-800 text-gray-100" : "bg-white text-gray-900"}`}>
                <label className="block px-4 py-2">
                  <input
                    type="checkbox"
                    checked={automatic}
                    onChange={() => setAutomatic(!automatic)}
                  />{" "}
                  Automatic
                </label>
                {columns.map((column) => (
                  <label key={column.key} className="block px-4 py-2">
                    <input
                      type="checkbox"
                      checked={visibleColumns.some((col) => col.key === column.key)}
                      onChange={() =>
                        automatic || toggleColumnVisibility(column.key)
                      }
                    />{" "}
                    {column.label}
                  </label>
                ))}
              </div>
            }
          >
            Select Columns
          </Button>
        </div>
      )}
      <div ref={tableRef} className="overflow-x-auto">
        <table
          className={`min-w-full table-auto ${theme === "dark" ? "bg-gray-800" : "bg-white"}`}
        >
          <thead>
            <tr>
              {visibleColumns.map((column, colIndex) => (
                <th
                  key={column.key}
                  onClick={() =>
                    column.sortable !== false && requestSort(column.key)
                  }
                  className={`px-4 py-2 cursor-pointer ${column.sortable === false ? "cursor-default" : ""} ${theme === "dark" ? "text-gray-300" : "text-gray-700"} ${theme === "dark" ? "bg-gray-900" : "bg-gray-200"} ${automatic ? "" : "resize-handle"}`}
                  style={{ width: column.width || "150px" }}
                >
                  {column.label}
                  {!automatic && (
                    <div
                      className="resize-handle"
                      onMouseDown={(e) => handleMouseDown(e, colIndex)}
                      style={{
                        cursor: "col-resize",
                        position: "absolute",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: "5px",
                        background: "transparent",
                      }}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, rowIndex) => (
              <tr key={rowIndex} className="border-t">
                {visibleColumns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="px-4 py-2 whitespace-normal break-words"
                  >
                    {column.render
                      ? column.render(item[column.key], item)
                      : item[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default SortableTable;
