import React, { useContext } from "react";
import { ThemeContext } from "../layouts/ThemeContext";

const DeckBuilder = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`p-4 md:p-6 rounded shadow ${
        theme === "dark"
          ? "bg-gray-800 text-gray-100"
          : "bg-white text-gray-900"
      }`}
    >
      <h1 className="text-xl md:text-2xl font-bold mb-4">Deck Builder</h1>
      <p className="text-sm md:text-base">Build and manage your decks here.</p>
    </div>
  );
};

export default DeckBuilder;
