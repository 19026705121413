import React, { useContext } from "react";
import { ThemeContext } from "../layouts/ThemeContext";
import { AuthContext } from "../../services/AuthContext";
import Card from "../misc/Card";
import Table from "../misc/SortableTable";
import Chart from "../misc/Chart";

const DashboardHome = () => {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  // Example data for the Table component
  const tableData = [
    { id: 1, name: "Item 1", value: 100 },
    { id: 2, name: "Item 2", value: 200 },
  ];

  const tableColumns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Value", accessor: "value" },
  ];

  // Example data for the Chart component
  const chartData = {
    labels: ["Item 1", "Item 2"],
    datasets: [
      {
        label: "Collection Overview",
        data: [100, 200],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className={`p-4 ${theme === "dark" ? "bg-gray-900" : "bg-gray-100"}`}>
      <Card title={`${user.username}'s Dashboard`} titleSize="text-4xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="lg:col-span-1">
            <Card title="Collection Statistics" titleSize="text-2xl" collapsible={true}>
              <Table
                data={tableData}
                columns={tableColumns}
                title="Statistics"
                titleSize="text-xl"
              />
            </Card>
          </div>
          <div className="lg:col-span-1">
            <Card title="Collection Chart" titleSize="text-2xl" collapsible={true}>
              <div className="w-full h-64 md:h-72 lg:h-80">
                <Chart
                  type="pie"
                  data={chartData}
                  options={chartOptions}
                  title="Collection Overview"
                  titleSize="text-xl"
                />
              </div>
            </Card>
          </div>
        </div>

        <div className="mt-4">
          <Card title="Collection Information" titleSize="text-2xl" collapsible={true}>
            <Table
              data={tableData}
              columns={tableColumns}
              title="Overview"
              titleSize="text-xl"
            />
          </Card>
        </div>
      </Card>
    </div>
  );
};

export default DashboardHome;
