import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Card from '../components/misc/Card';
import Button from '../components/forms/Button';
import BaseLayout from '../components/layouts/BaseLayout';
import { ThemeContext } from '../components/layouts/ThemeContext';

function Pricing() {
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();

    const barColor = theme === "dark" ? "bg-[#003268]" : "bg-[#FF6F61]";
    const logoSrc = theme === "dark" ? "/static/logo_dark_full.png" : "/static/logo_light_full.png";

    return (
        <BaseLayout>
            <section className="text-center py-20">
                <Card
                    title="Choose the Perfect Plan for Your Magic: The Gathering Experience"
                    titleSize="text-3xl"
                    titlePosition="center"
                    logoSrc={logoSrc}
                    logoPosition="top-left"
                >
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <p className="text-lg mb-10 px-8">
                        At CardRealm, we offer flexible pricing plans to suit the needs of every Magic: The Gathering player, whether you're a casual collector or a competitive pro. Each plan is designed to provide you with the tools and features you need to manage your collections, build powerful decks, and connect with the community.
                    </p>
                </Card>
            </section>

            <section className="py-20">
                <Card
                    title="Pricing Plans:"
                    titleSize="text-3xl"
                    titlePosition="center"
                >
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />

                    <div className="flex flex-col space-y-8 items-center mt-8">
                        <Card
                            title="Free Plan"
                            titleSize="text-3xl"
                            titlePosition="center"
                            className="border border-green-300 rounded-lg shadow-lg"
                        >
                            <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                                <div className="flex-1 md:mr-8">
                                    <p className="text-left">
                                        Perfect for beginners and casual players
                                    </p>
                                    <ul className="list-disc list-inside mt-4">
                                        <li>Manage up to 10,000 cards in your collection</li>
                                        <li>Basic deck building tools</li>
                                        <li>Access to the card database</li>
                                        <li>Price tracking for up to 10 cards</li>
                                        <li>Community access and social features</li>
                                        <li>Basic support</li>
                                    </ul>
                                    <div className="mt-4">
                                        <Button
                                            onClick={() => navigate("/signup")}
                                            className="w-full md:w-auto"
                                        >
                                            Sign Up
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-8 md:mt-0">
                                    <img src="/static/free_plan.png" alt="Free Plan" className="w-full max-w-xs md:max-w-sm" />
                                </div>
                            </div>
                        </Card>

                        <Card
                            title="Pro Plan"
                            titleSize="text-3xl"
                            titlePosition="center"
                            className="border border-green-300 rounded-lg shadow-lg"
                        >
                            <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                                <div className="flex-1 md:mr-8">
                                    <p className="text-left">
                                        Ideal for dedicated players and collectors
                                    </p>
                                    <ul className="list-disc list-inside mt-4">
                                        <li>Unlimited card collection management</li>
                                        <li>Advanced deck building tools</li>
                                        <li>Comprehensive card database access</li>
                                        <li>Real-time price tracking and alerts</li>
                                        <li>Trade and wish list management</li>
                                        <li>Priority community access and social features</li>
                                        <li>Priority support</li>
                                    </ul>
                                    <div className="mt-4">
                                        <p>Starting at $9.99/month</p>
                                        <Button
                                            onClick={() => navigate("/signup")}
                                            className="w-full md:w-auto"
                                        >
                                            Upgrade to Pro
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-8 md:mt-0">
                                    <img src="/static/pro_plan.png" alt="Pro Plan" className="w-full max-w-xs md:max-w-sm" />
                                </div>
                            </div>
                        </Card>

                        <Card
                            title="Elite Plan"
                            titleSize="text-3xl"
                            titlePosition="center"
                            className="border border-green-300 rounded-lg shadow-lg"
                        >
                            <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                                <div className="flex-1 md:mr-8">
                                    <p className="text-left">
                                        Designed for competitive players and professionals
                                    </p>
                                    <ul className="list-disc list-inside mt-4">
                                        <li>All features in the Pro Plan</li>
                                        <li>Advanced analytics and insights</li>
                                        <li>Customizable notifications</li>
                                        <li>Access to exclusive community events</li>
                                        <li>Early access to new features</li>
                                        <li>Dedicated customer support</li>
                                    </ul>
                                    <div className="mt-4">
                                        <p>Starting at $19.99/month</p>
                                        <Button
                                            onClick={() => navigate("/signup")}
                                            className="w-full md:w-auto"
                                        >
                                            Upgrade to Elite
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-8 md:mt-0">
                                    <img src="/static/elite_plan.png" alt="Elite Plan" className="w-full max-w-xs md:max-w-sm" />
                                </div>
                            </div>
                        </Card>

                        <Card
                            title="Enterprise Plan"
                            titleSize="text-3xl"
                            titlePosition="center"
                            className="border border-green-300 rounded-lg shadow-lg"
                        >
                            <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                                <div className="flex-1 md:mr-8">
                                    <p className="text-left">
                                        For gaming stores, organizations, and large-scale collectors
                                    </p>
                                    <ul className="list-disc list-inside mt-4">
                                        <li>All features in the Elite Plan</li>
                                        <li>Multiple user accounts</li>
                                        <li>Custom integrations and APIs</li>
                                        <li>Dedicated account manager</li>
                                        <li>Personalized training and support</li>
                                        <li>Custom pricing based on requirements</li>
                                    </ul>
                                    <div className="mt-4">
                                        <p>Contact for pricing information</p>
                                        <Button
                                            onClick={() => navigate("/signup")}
                                            className="w-full md:w-auto"
                                        >
                                            Upgrade to Enterprise
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-8 md:mt-0">
                                    <img src="/static/enterprise_plan.png" alt="Enterprise Plan" className="w-full max-w-xs md:max-w-sm" />
                                </div>
                            </div>
                        </Card>
                    </div>
                </Card>
            </section>
        </BaseLayout>
    );
}

export default Pricing;
