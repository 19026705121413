import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";

const Footer = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <footer
      className={`w-full py-4 md:py-6 mt-auto transition-colors duration-300 ${theme === "dark"
        ? "bg-gray-800 text-gray-100"
        : "bg-gray-100 text-gray-900"
        }`}
    >
      <div className="container mx-auto text-center">
        <p className="text-sm md:text-base">&copy; 2024 CardRealm. All rights reserved.</p>
        <div className="flex justify-center space-x-4 mt-2">
          <a
            href="#"
            className={`transition-colors duration-300 ${theme === "dark" ? "hover:text-gray-400" : "hover:text-gray-700"
              }`}
            aria-label="Facebook"
          >
            Facebook
          </a>
          <a
            href="#"
            className={`transition-colors duration-300 ${theme === "dark" ? "hover:text-gray-400" : "hover:text-gray-700"
              }`}
            aria-label="Twitter"
          >
            Twitter
          </a>
          <a
            href="#"
            className={`transition-colors duration-300 ${theme === "dark" ? "hover:text-gray-400" : "hover:text-gray-700"
              }`}
            aria-label="Instagram"
          >
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
