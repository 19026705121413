import React, { useContext, useEffect, useState, useCallback } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { ThemeContext } from "../layouts/ThemeContext";
import axios from "axios";
import { renderMana } from "../../functions/renderMana";
import { Tooltip } from "react-tooltip";



// Helper function for fetching autocomplete options
const fetchAutocompleteOptions = async (inputValue, endpoint, setAutocompleteOptions, setLoading) => {
  if (inputValue.length >= 3) {
    setLoading(true);
    try {
      const response = await axios.get(`${endpoint}?query=${inputValue}`);
      const options = response.data.map((item) => ({
        value: item.name,
        label: item.name,
        mana_cost: item.mana_cost,
        image_url: item.image_url,
        usd_price: item.usd_price,
      }));
      setAutocompleteOptions(options);
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    } finally {
      setLoading(false);
    }
  }
};

// Custom option component to display additional card details
const CustomOption = ({ data, showCardDetails, ...props }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <components.Option {...props}>
      {showCardDetails && data.image_url && (
        <img
          src={data.image_url}
          alt={data.label}
          style={{ width: 40, height: 40, marginRight: 10, borderRadius: "4px" }}
        />
      )}
      <div>
        <div style={{ color: theme === "dark" ? "#E2E8F0" : "#1A202C" }}>{data.label}</div>
        {showCardDetails && (
          <div
            style={{
              fontSize: "0.8em",
              color: theme === "dark" ? "#A0AEC0" : "#666",
              display: "flex",
              alignItems: "center",
            }}
          >
            {renderMana(data.mana_cost)}
            <span style={{ marginLeft: 5 }}>${data.usd_price || "N/A"}</span>
          </div>
        )}
      </div>
    </components.Option>
  );
};

const InputField = ({
  label,
  name,
  value = "",
  onChange,
  type = "text",
  options = [],
  className = "",
  placeholder = "Select...",
  isMulti = false,
  isSearchable = false,
  isAutocomplete = false,
  autocompleteEndpoint = "/api/cards/names/",
  showCardDetails = false,
  isOperator = false,
  tooltip = "", // Added tooltip prop
}) => {
  const { theme } = useContext(ThemeContext);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // Fetch autocomplete options when the input value changes
  useEffect(() => {
    if (isAutocomplete && inputValue) {
      fetchAutocompleteOptions(inputValue, autocompleteEndpoint, setAutocompleteOptions, setLoading);
    }
  }, [inputValue, isAutocomplete, autocompleteEndpoint]);

  // Handle select or input change
  const handleChange = useCallback(
    (selectedOption) => {
      const selectedValue = isMulti
        ? selectedOption?.map((option) => option.value) || []
        : selectedOption?.value || inputValue;

      onChange({ target: { name, value: selectedValue } });
      if (!isMulti) setInputValue(selectedValue);
    },
    [inputValue, isMulti, onChange]
  );

  // Styles for the select input based on the theme
  const selectStyles = useCallback(
    () => ({
      control: (provided) => ({
        ...provided,
        backgroundColor: theme === "dark" ? "#2D3748" : "#fff",
        borderColor: theme === "dark" ? "#4A5568" : "#E2E8F0",
        minHeight: isOperator ? "30px" : "40px",
        fontSize: isOperator ? "0.85rem" : "1rem",
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: theme === "dark" ? "#2D3748" : "#fff",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
          ? theme === "dark"
            ? "#4A5568"
            : "#E2E8F0"
          : theme === "dark"
            ? "#2D3748"
            : "#fff",
        color: state.isSelected
          ? theme === "dark"
            ? "#E2E8F0"
            : "#1A202C"
          : theme === "dark"
            ? "#E2E8F0"
            : "#1A202C",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: theme === "dark" ? "#E2E8F0" : "#1A202C",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: theme === "dark" ? "#A0AEC0" : "#718096",
      }),
      input: (provided) => ({
        ...provided,
        color: theme === "dark" ? "#E2E8F0" : "#1A202C",
      }),
    }),
    [theme, isOperator]
  );

  // Conditional rendering for select, multi-select, or autocomplete inputs
  if (type === "select" || type === "multi-select" || type === "dropdown" || isAutocomplete) {
    return (
      <div className={`mb-4 ${className} ${isOperator ? "w-1/2" : ""}`}>
        <label
          className={`block text-sm font-medium ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}
          data-tooltip-id={tooltip ? `${name}-tooltip` : undefined} // Add tooltip id if provided
          data-tooltip-content={tooltip}
        >
          {label}
        </label>
        <CreatableSelect
          name={name}
          value={
            isMulti
              ? options.filter((option) => value.includes(option.value))
              : options.find((option) => option.value === value) || { label: value, value }
          }
          onChange={handleChange}
          options={isAutocomplete ? autocompleteOptions : options}
          isMulti={isMulti}
          isSearchable={isSearchable || isAutocomplete}
          onInputChange={(newValue) => setInputValue(newValue)}
          inputValue={inputValue}
          isLoading={loading}
          styles={selectStyles()}
          className="mt-1 block w-full"
          placeholder={placeholder}
          components={{ Option: (props) => <CustomOption {...props} showCardDetails={showCardDetails} /> }}
        />
        {tooltip && <Tooltip id={`${name}-tooltip`} place="top" effect="solid" />}
      </div>
    );
  }

  // Return regular text or number input
  return (
    <div className={`mb-4 ${className} ${isOperator ? "w-full sm:w-1/2 inline-block" : "block"}`}>
      <label
        className={`block text-sm font-medium ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}
        data-tooltip-id={tooltip ? `${name}-tooltip` : undefined} // Add tooltip id if provided
        data-tooltip-content={tooltip}
      >
        {label}
      </label>
      <input
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        className={`${theme === "dark" ? "bg-gray-800 text-gray-300" : "bg-white text-gray-900"} 
                    ${theme === "dark" ? "border-gray-700" : "border-gray-300"} 
                    mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none sm:text-sm rounded-md border`}
        placeholder={placeholder}
      />
      {tooltip && <Tooltip id={`${name}-tooltip`} place="top" effect="solid" />}
    </div>
  );
};

export default InputField;
