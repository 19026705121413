import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { ThemeContext } from "./ThemeContext";
import { renderMana } from "../../functions/renderMana";

const contentVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const BaseLayout = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  const [cookieValue, setCookieValue] = useState(null);

  useEffect(() => {
    const fetchCookie = () => {
      const cookieName = "cardrealm-auth";
      const cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${cookieName}=`))
        ?.split("=")[1];
      setCookieValue(cookie);
    };

    fetchCookie();
  }, []);

  const renderWithManaSymbols = (content) => {
    if (typeof content === "string") {
      return <span>{renderMana(content)}</span>;
    }
    return content;
  };

  return (
    <div
      className={`min-h-screen flex flex-col ${theme === "dark" ? "bg-[#003268] text-gray-100" : "bg-[#FF6F61] text-gray-900"
        } font-roboto`}
    >
      <Navbar />
      <motion.main
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={contentVariants}
        transition={{ duration: 0.5 }}
        className="flex-grow container mx-auto px-4 md:px-6 py-6 md:py-12"
      >
        {React.Children.map(children, (child) => renderWithManaSymbols(child))}
        {cookieValue && (
          <div
            className={`mt-4 p-4 rounded ${theme === "dark" ? "bg-gray-700" : "bg-gray-100"
              }`}
          >
            <p>Cookie Value: {cookieValue}</p>
          </div>
        )}
      </motion.main>
      <Footer />
    </div>
  );
};

export default BaseLayout;
