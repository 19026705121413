import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/misc/Card";
import BaseLayout from "../components/layouts/BaseLayout";
import Button from "../components/forms/Button";
import InputField from "../components/forms/InputField";
import Cookies from "js-cookie";

function RegisterPage() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    bio: "",
    location: "",
    birthDate: "",
    avatar: null, // Add avatar field
  });
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "avatar") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("first_name", formData.firstName);
    formDataToSend.append("last_name", formData.lastName);
    formDataToSend.append("username", formData.username);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("phone_number", formData.phoneNumber);
    formDataToSend.append("profile.bio", formData.bio); // Use dot notation
    formDataToSend.append("profile.location", formData.location);
    formDataToSend.append("profile.birth_date", formData.birthDate);
    if (formData.avatar) {
      formDataToSend.append("profile.avatar", formData.avatar);
    }

    try {
      const response = await fetch("/api/authentication/register/", {
        method: "POST",
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: formDataToSend,
      });

      if (response.ok) {
        const result = await response.json();
        setSuccess("Registration successful! Redirecting...");
        console.log("Profile Data:", result);
        navigate("/login");
      } else {
        const result = await response.json();
        setError(`Registration failed: ${result.error || "Unknown error"}`);
        setFieldErrors(result || {});
      }
    } catch (error) {
      setError(`Error during registration: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseLayout>
      <section className="flex justify-center items-center min-h-screen p-4">
        <Card title="Register for CardRealm" titleSize="text-3xl" imageSrc="/logo.png" className="w-full max-w-lg">
          <div className="w-full">
            <form className="space-y-4" onSubmit={handleRegister}>
              {["firstName", "lastName", "username", "email", "password", "confirmPassword", "phoneNumber", "bio", "location", "birthDate"].map((field) => (
                <div key={field}>
                  <InputField
                    label={field.replace(/([A-Z])/g, ' $1').trim()}
                    type={field === "password" || field === "confirmPassword" ? "password" : field === "email" ? "email" : field === "birthDate" ? "date" : "text"}
                    id={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    required={["firstName", "lastName", "username", "email", "password", "confirmPassword", "phoneNumber"].includes(field)}
                  />
                  {fieldErrors[field] && <p className="text-red-500">{fieldErrors[field]}</p>}
                </div>
              ))}
              <div>
                <label htmlFor="avatar" className="block text-gray-700">Avatar</label>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/*"
                  onChange={handleChange}
                  className="mt-2 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none"
                />
              </div>
              {error && <p className="text-red-500 text-center">{error}</p>}
              {success && <p className="text-green-500 text-center">{success}</p>}
              <Button type="submit" className="w-full" disabled={isSubmitting}>
                {isSubmitting ? "Registering..." : "Register"}
              </Button>
              <div className="flex justify-center mt-4">
                <span className="text-sm">Already have an account? </span>
                <a href="/login" className="ml-1 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  Login
                </a>
              </div>
            </form>
          </div>
        </Card>
      </section>
    </BaseLayout>
  );
}

export default RegisterPage;
