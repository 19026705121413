import React, { useContext, useState } from "react";
import { ThemeContext } from "../layouts/ThemeContext";
import { FaPlus, FaMinus } from "react-icons/fa";

const Card = ({
  children,
  className = "",
  collapsible = false,
  title = "",
  titleSize = "text-3xl",
  imageSrc = "", // Prop for the image source
  logoSrc = "", // Prop for the logo source
  logoPosition = "top-left", // Prop for the logo position
  titlePosition = "left", // New prop for the title position
}) => {
  const { theme } = useContext(ThemeContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Determine the logo position classes
  const logoPositionClasses = {
    "top-left": "absolute top-4 left-4",
    "top-center": "absolute top-4 left-1/2 transform -translate-x-1/2",
    "top-right": "absolute top-4 right-4",
  };

  // Determine the title position classes
  const titlePositionClasses = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
  };

  return (
    <div
      className={`relative p-6 rounded-lg shadow-lg transition duration-300 ${className} ${theme === "dark"
        ? "bg-gray-800 text-gray-100"
        : "bg-white text-gray-900"
        }`}
    >
      {logoSrc && (
        <div className={logoPositionClasses[logoPosition]}>
          <img src={logoSrc} alt="Card logo" className="h-12" />
        </div>
      )}
      {imageSrc && (
        <div className="flex justify-center mb-4">
          <img src={imageSrc} alt="Card image" className="h-12" />
        </div>
      )}
      {title && (
        <div className={`mb-4 ${titlePositionClasses[titlePosition]}`}>
          <h2 className={`${titleSize} font-bold`}>{title}</h2>
          {collapsible && (
            <button
              onClick={toggleCollapse}
              className={`ml-2 p-2 rounded ${theme === "dark"
                ? "bg-gray-700 text-gray-300"
                : "bg-gray-200 text-gray-700"
                }`}
            >
              {isCollapsed ? <FaPlus /> : <FaMinus />}
            </button>
          )}
        </div>
      )}
      {!isCollapsed && children}
    </div>
  );
};

export default Card;
