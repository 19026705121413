import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { renderMana } from "../../functions/renderMana";
import InputField from "../forms/InputField";
import { ThemeContext } from "../layouts/ThemeContext";

const AddByNameQuick = ({ onSelectCard }) => {
    const { theme } = useContext(ThemeContext);
    const [query, setQuery] = useState("");
    const [cards, setCards] = useState([]);
    const [filteredCards, setFilteredCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCards = async () => {
            if (!query) {
                setFilteredCards([]);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(
                    `https://api.scryfall.com/cards/search?q=${encodeURIComponent(
                        query
                    )}&order=name`
                );
                setCards(response.data.data);
            } catch (error) {
                setError("Error fetching cards");
            } finally {
                setLoading(false);
            }
        };

        fetchCards();
    }, [query]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (query.length > 0) {
                setFilteredCards(
                    cards.filter((card) =>
                        card.name.toLowerCase().includes(query.toLowerCase())
                    )
                );
            } else {
                setFilteredCards([]);
            }
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [query, cards]);

    const handleCardSelect = (card) => {
        onSelectCard(card);
        setQuery(""); // Reset the query after selection
        setFilteredCards([]);
    };

    return (
        <div className="relative w-full max-w-xs mx-auto">
            <InputField
                type="text"
                placeholder="Search for a card..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="w-full"
                dropDown
            />
            {loading && (
                <div
                    className={`absolute inset-x-0 top-0 mt-8 p-2 ${theme === "dark" ? "bg-gray-800" : "bg-white"
                        } border ${theme === "dark" ? "border-gray-700" : "border-gray-300"
                        } rounded shadow-lg z-10`}
                >
                    <p>Loading...</p>
                </div>
            )}
            {error && (
                <div
                    className={`absolute inset-x-0 top-0 mt-8 p-2 ${theme === "dark" ? "bg-red-900" : "bg-red-100"
                        } text-red-500 border ${theme === "dark" ? "border-red-700" : "border-red-300"
                        } rounded shadow-lg z-10`}
                >
                    <p>{error}</p>
                </div>
            )}
            {filteredCards.length > 0 && (
                <ul
                    className={`absolute inset-x-0 top-0 mt-8 max-h-48 overflow-y-auto ${theme === "dark" ? "bg-gray-800" : "bg-white"
                        } border ${theme === "dark" ? "border-gray-700" : "border-gray-300"
                        } rounded shadow-lg z-10`}
                >
                    {filteredCards.map((card) => (
                        <li
                            key={card.id}
                            className={`p-2 flex items-center justify-between cursor-pointer ${theme === "dark"
                                ? "hover:bg-gray-700"
                                : "hover:bg-gray-200"
                                }`}
                            onClick={() => handleCardSelect(card)}
                        >
                            <div className="flex items-center">
                                <span className="mr-2">{renderMana(card.mana_cost)}</span>
                                <span>{card.name}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AddByNameQuick;
