// services/csrf.js
import Cookies from 'js-cookie';

export const getCsrfToken = async () => {
  try {
    const response = await fetch('/api/authentication/csrf-token/', {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();
    Cookies.set('csrftoken', data.csrfToken, { sameSite: 'Strict', secure: true });
    return data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};
