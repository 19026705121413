import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import VerifyEmail from "./pages/VerifyEmail";
import { ThemeProvider } from "./components/layouts/ThemeContext";
import { AuthProvider } from "./services/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import DashboardLayout from "./components/layouts/DashboardLayout";
import DashboardHome from "./components/dashboard/DashboardHome";
import DeckBuilder from "./components/dashboard/DeckBuilder";
import CardDatabaseLanding from "./components/dashboard/CardDatabaseLanding";
import CardDetails from "./pages/CardDetails";
import Background from "./components/layouts/Background";
import Loading from "./components/misc/Loader";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import Collection from "./pages/Collection";
import About from "./pages/About";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import "./index.css";

import axios from "axios";
import Cookies from "js-cookie";

// Variants for page animations.
const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.9,
  },
};

// Transition settings for page animations.
const pageTransition = {
  type: "spring",
  stiffness: 260,
  damping: 20,
};

// Component to wrap children with animation effects.
const AnimatedPage = ({ children }) => (
  <motion.div
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}
    transition={pageTransition}
    className="relative z-10"
  >
    {children}
  </motion.div>
);

// Component to handle animated routing.
const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <AnimatedPage>
              <HomePage />
            </AnimatedPage>
          }
        />
        <Route
          path="/about"
          element={
            <AnimatedPage>
              <About />
            </AnimatedPage>
          }
        />
        <Route
          path="/features"
          element={
            <AnimatedPage>
              <Features />
            </AnimatedPage>
          }
        />
        <Route
          path="/pricing"
          element={
            <AnimatedPage>
              <Pricing />
            </AnimatedPage>
          }
        />
        <Route
          path="/contact"
          element={
            <AnimatedPage>
              <Contact />
            </AnimatedPage>
          }
        />
        <Route
          path="/login"
          element={
            <AnimatedPage>
              <LoginPage />
            </AnimatedPage>
          }
        />
        <Route
          path="/signup"
          element={
            <AnimatedPage>
              <SignupPage />
            </AnimatedPage>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <AnimatedPage>
                <Profile />
              </AnimatedPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AnimatedPage>
              <ForgotPasswordPage />
            </AnimatedPage>
          }
        />
        <Route
          path="/reset-password/:userId/:token"
          element={
            <AnimatedPage>
              <ResetPasswordPage />
            </AnimatedPage>
          }
        />
        <Route path="/verify-email/:uid/:token" element={<VerifyEmail />} />  {/* Add new route */}
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >

          <Route
            index
            element={
              <AnimatedPage>
                <DashboardHome />
              </AnimatedPage>
            }
          />
          <Route
            path="collection"
            element={
              <AnimatedPage>
                <Collection />
              </AnimatedPage>
            }
          />
          <Route
            path="deckbuilder"
            element={
              <AnimatedPage>
                <DeckBuilder />
              </AnimatedPage>
            }
          />
          <Route
            path="carddatabase"
            element={
              <AnimatedPage>
                <CardDatabaseLanding />
              </AnimatedPage>
            }
          />
          <Route
            path="cards/:cardId"
            element={
              <AnimatedPage>
                <CardDetails />
              </AnimatedPage>
            }
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

// Main application component.
const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        console.log("Fetching CSRF token...");
        const response = await axios.get("/api/authentication/csrf-token/");
        const csrfToken = response.data.csrfToken;
        Cookies.set("csrftoken", csrfToken);
        console.log("CSRF token fetched and set:", csrfToken);
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
      }
    };

    fetchCsrfToken();
    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <Background /> {/* Always render the background */}
          <AnimatedRoutes />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
