import React, { useContext } from 'react';
import Card from '../components/misc/Card';
import Button from '../components/forms/Button';
import BaseLayout from '../components/layouts/BaseLayout';
import { ThemeContext } from '../components/layouts/ThemeContext';

const Contact = () => {
    const { theme } = useContext(ThemeContext);

    return (
        <BaseLayout>
            <Card
                className="shadow-lg max-w-xl mx-auto mt-4"
                style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.textColor,
                }}
            >
                <h1 className="text-2xl text-center mb-4">Contact Us</h1>
                <p className="text-lg">
                    If you have any questions or suggestions, please feel free to contact
                    us.
                </p>
                <div className="flex justify-center mt-4 mb-4">
                    <a
                        href="mailto:simon.lefebvre@cardrealm.io"
                        className="text-lg"
                        style={{
                            color: theme.linkColor,
                        }}
                    >
                        simon.lefebvre@cardrealm.io
                    </a>
                </div>
                <div className="flex justify-center">
                    <Button
                        className="text-lg px-4 py-2"
                        style={{ backgroundColor: theme.buttonColor }}
                    >
                        <a
                            href="https://discord.gg/cardrealm"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white hover:text-gray-200"
                        >
                            Join Discord
                        </a>
                    </Button>
                </div>
            </Card>
        </BaseLayout>
    );
};

export default Contact;
