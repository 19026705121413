import React, { useState, useContext } from "react";
import Card from "../components/misc/Card";
import BaseLayout from "../components/layouts/BaseLayout";
import { ThemeContext } from "../components/layouts/ThemeContext";
import Button from "../components/forms/Button";
import InputField from "../components/forms/InputField";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/AuthContext";

function LoginPage() {
  const { theme } = useContext(ThemeContext);
  const { login } = useContext(AuthContext); // Use the AuthContext
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        email,
        password,
      };

      const response = await fetch("/api/authentication/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Login successful", result);
        setSuccess("Login successful! Redirecting...");

        // Call the login method from AuthContext
        login(result.token);

        // Redirect to the dashboard
        navigate("/dashboard");
      } else {
        const result = await response.json();
        console.error("Login failed:", result);
        setError(`Login failed: ${result.error || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError(`Error during login: ${error.message}`);
    }
  };

  return (
    <BaseLayout>
      <section className="flex justify-center items-center h-screen">
        <Card title="Login to CardRealm" titleSize="text-3xl" imageSrc="/logo.png">
          <div className={`w-full ${theme === "dark" ? "text-gray-300" : "text-gray-600"}`}>
            <form className="space-y-6" onSubmit={handleLogin}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <InputField label="Email" type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div>
                  <InputField label="Password" type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
              </div>
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              {error && <p className="text-red-500 text-center">{error}</p>}
              {success && <p className="text-green-500 text-center">{success}</p>}
              <Button type="submit" className="w-full">Login</Button>
              <div className="flex justify-center mt-4">
                <span className="text-sm text-gray-600">Don't have an account? </span>
                <a
                  href="/signup"
                  className="ml-1 text-sm font-medium"
                >
                  Register
                </a>
              </div>
            </form>
          </div>
        </Card>
      </section>
    </BaseLayout>
  );
}

export default LoginPage;
