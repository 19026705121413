// src/components/misc/Filter.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import InputField from "../forms/InputField";
import { ThemeContext } from "../layouts/ThemeContext";
import { renderMana } from "../../functions/renderMana";
import Button from "../forms/Button";

const Filter = ({ filter, setFilter }) => {
  const { theme } = useContext(ThemeContext);
  const [sets, setSets] = useState([]);
  const [localFilter, setLocalFilter] = useState(filter);

  // Conditions for various fields
  const [manaCostCondition, setManaCostCondition] = useState("=");
  const [powerCondition, setPowerCondition] = useState("=");
  const [toughnessCondition, setToughnessCondition] = useState("=");
  const [keywordsCondition, setKeywordsCondition] = useState("|");
  const [cardTypeCondition, setCardTypeCondition] = useState("|");
  const [rarityCondition, setRarityCondition] = useState("|");
  const [legalitiesCondition, setLegalitiesCondition] = useState("|");
  const [superTypeCondition, setSuperTypeCondition] = useState("|");
  const [planeswalkerTypeCondition, setPlaneswalkerTypeCondition] = useState("|");
  const [creatureTypeCondition, setCreatureTypeCondition] = useState("|");
  const [landTypeCondition, setLandTypeCondition] = useState("|");

  // Data for multi-selects
  const [keywords, setKeywords] = useState([]);
  const [cardTypes, setCardTypes] = useState([]);
  const [rarities, setRarities] = useState([]);
  const [legalities, setLegalities] = useState([]);
  const [colors, setColors] = useState([]);
  const [cardNames, setCardNames] = useState([]);
  const [superTypes, setSuperTypes] = useState([]);
  const [planeswalkerTypes, setPlaneswalkerTypes] = useState([]);
  const [creatureTypes, setCreatureTypes] = useState([]);
  const [landTypes, setLandTypes] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [oracleText, setOracleText] = useState([]);

  // State for mana cost in exact mode
  const [manaCounts, setManaCounts] = useState({});
  const [selectedManaSymbol, setSelectedManaSymbol] = useState("");
  const [manaQuantity, setManaQuantity] = useState(1);
  const [combinedManaCost, setCombinedManaCost] = useState("");

  // State for colors
  const [selectedColors, setSelectedColors] = useState([]);
  const [showMoreColors, setShowMoreColors] = useState(false);

  // State for symbols
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const [showMoreSymbols, setShowMoreSymbols] = useState(false);

  // Toggle for additional filters
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  // Mana Cost Filter Mode
  const [colorMode, setColorMode] = useState("contains"); // Added state for colorMode

  // Search summary
  const [searchSummary, setSearchSummary] = useState("");

  const limitedColors = colors.slice(0, 8); // Adjust the number as needed

  // Define limited symbols to display initially
  const limitedSymbols = symbols.slice(0, 8); // Adjust the number as needed

  // Fetch data from the API
  const fetchData = async (url, setData, mapFn) => {
    try {
      const response = await axios.get(url);
      if (response.data && Array.isArray(response.data)) {
        setData(response.data.map(mapFn));
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  // Helper function to get selected option objects based on current condition
  const getSelectedOptions = (condition, fieldOptions, selectedValues) => {
    return selectedValues
      ? fieldOptions.filter((option) => selectedValues.includes(option.value))
      : [];
  };

  useEffect(() => {
    fetchData("/api/sets/", setSets, (set) => ({ value: set.code, label: set.name }));
    fetchData("/api/utils/keywords/", setKeywords, (keyword) => ({ value: keyword.name, label: keyword.name }));
    fetchData("/api/utils/card_types/", setCardTypes, (type) => ({ value: type.name, label: type.name }));
    fetchData("/api/utils/rarities/", setRarities, (rarity) => ({ value: rarity.name, label: rarity.name }));
    fetchData("/api/utils/legal_formats/", setLegalities, (legality) => ({ value: legality.name, label: legality.name }));
    fetchData("/api/utils/colors/", setColors, (color) => ({ value: color.name, label: color.code }));
    fetchData("/api/cards/names/", setCardNames, (card) => ({ value: card.name, label: card.name }));
    fetchData("/api/utils/super_types/", setSuperTypes, (superType) => ({ value: superType.name, label: superType.name }));
    fetchData("/api/utils/planeswalker_types/", setPlaneswalkerTypes, (planeswalkerType) => ({ value: planeswalkerType.name, label: planeswalkerType.name }));
    fetchData("/api/utils/creature_types/", setCreatureTypes, (creatureType) => ({ value: creatureType.name, label: creatureType.name }));
    fetchData("/api/utils/land_types/", setLandTypes, (landType) => ({ value: landType.name, label: landType.name }));
    fetchData("/api/utils/symbols/", setSymbols, (symbol) => ({ value: symbol.name, label: symbol.code }));
  }, []);

  // Update the search summary based on filter state
  useEffect(() => {
    let summary = [];

    // Helper function to map codes to labels
    const mapCodesToLabels = (codes, dataset) => {
      return codes
        .map((code) => {
          const item = dataset.find((entry) => entry.value === code);
          return item ? item.label : code;
        })
        .join(", ");
    };

    // Card Name
    if (localFilter.name) {
      summary.push(`Name: ${localFilter.name}`);
    }

    // Set Code
    if (localFilter.set_code && localFilter.set_code.length > 0) {
      summary.push(`Set(s): ${mapCodesToLabels(localFilter.set_code, sets)}`);
    }

    // Mana Cost
    if (combinedManaCost) {
      summary.push(`Mana Cost: ${combinedManaCost}`);
    }

    // Power
    if (localFilter.power) {
      summary.push(`Power: ${localFilter.power}`);
    }
    if (localFilter.power__lt) {
      summary.push(`Power: < ${localFilter.power__lt}`);
    }
    if (localFilter.power__gt) {
      summary.push(`Power: > ${localFilter.power__gt}`);
    }

    // Toughness
    if (localFilter.toughness) {
      summary.push(`Toughness: ${localFilter.toughness}`);
    }
    if (localFilter.toughness__lt) {
      summary.push(`Toughness: < ${localFilter.toughness__lt}`);
    }
    if (localFilter.toughness__gt) {
      summary.push(`Toughness: > ${localFilter.toughness__gt}`);
    }

    // Rarities
    if (localFilter.rarity__or && localFilter.rarity__or.length > 0) {
      summary.push(`Rarity(s) (OR): ${mapCodesToLabels(localFilter.rarity__or, rarities)}`);
    }
    if (localFilter.rarity__and && localFilter.rarity__and.length > 0) {
      summary.push(`Rarity(s) (AND): ${mapCodesToLabels(localFilter.rarity__and, rarities)}`);
    }
    if (localFilter.rarity__only && localFilter.rarity__only.length > 0) {
      summary.push(`Rarity(s) (ONLY): ${mapCodesToLabels(localFilter.rarity__only, rarities)}`);
    }

    // Keywords
    if (localFilter.keywords__or && localFilter.keywords__or.length > 0) {
      summary.push(`Keyword(s) (OR): ${mapCodesToLabels(localFilter.keywords__or, keywords)}`);
    }
    if (localFilter.keywords__and && localFilter.keywords__and.length > 0) {
      summary.push(`Keyword(s) (AND): ${mapCodesToLabels(localFilter.keywords__and, keywords)}`);
    }
    if (localFilter.keywords__only && localFilter.keywords__only.length > 0) {
      summary.push(`Keyword(s) (ONLY): ${mapCodesToLabels(localFilter.keywords__only, keywords)}`);
    }

    // Legalities
    if (localFilter.legalities__or && localFilter.legalities__or.length > 0) {
      summary.push(`Legalities (OR): ${mapCodesToLabels(localFilter.legalities__or, legalities)}`);
    }
    if (localFilter.legalities__and && localFilter.legalities__and.length > 0) {
      summary.push(`Legalities (AND): ${mapCodesToLabels(localFilter.legalities__and, legalities)}`);
    }
    if (localFilter.legalities__only && localFilter.legalities__only.length > 0) {
      summary.push(`Legalities (ONLY): ${mapCodesToLabels(localFilter.legalities__only, legalities)}`);
    }

    // Colors
    if (selectedColors.length > 0) {
      summary.push(`Colors: ${selectedColors.join(", ")}`);
    }

    // CMC
    if (localFilter.cmc) {
      summary.push(`CMC: ${localFilter.cmc}`);
    }
    if (localFilter.cmc__lt) {
      summary.push(`CMC: < ${localFilter.cmc__lt}`);
    }
    if (localFilter.cmc__gt) {
      summary.push(`CMC: > ${localFilter.cmc__gt}`);
    }

    // Card Types
    if (localFilter.card_types__or && localFilter.card_types__or.length > 0) {
      summary.push(`Card Types (OR): ${mapCodesToLabels(localFilter.card_types__or, cardTypes)}`);
    }
    if (localFilter.card_types__and && localFilter.card_types__and.length > 0) {
      summary.push(`Card Types (AND): ${mapCodesToLabels(localFilter.card_types__and, cardTypes)}`);
    }
    if (localFilter.card_types__only && localFilter.card_types__only.length > 0) {
      summary.push(`Card Types (ONLY): ${mapCodesToLabels(localFilter.card_types__only, cardTypes)}`);
    }

    // Super Types
    if (localFilter.super_types__or && localFilter.super_types__or.length > 0) {
      summary.push(`Super Types (OR): ${mapCodesToLabels(localFilter.super_types__or, superTypes)}`);
    }
    if (localFilter.super_types__and && localFilter.super_types__and.length > 0) {
      summary.push(`Super Types (AND): ${mapCodesToLabels(localFilter.super_types__and, superTypes)}`);
    }
    if (localFilter.super_types__only && localFilter.super_types__only.length > 0) {
      summary.push(`Super Types (ONLY): ${mapCodesToLabels(localFilter.super_types__only, superTypes)}`);
    }

    // Planeswalker Types
    if (localFilter.planeswalker_types__or && localFilter.planeswalker_types__or.length > 0) {
      summary.push(`Planeswalker Types (OR): ${mapCodesToLabels(localFilter.planeswalker_types__or, planeswalkerTypes)}`);
    }
    if (localFilter.planeswalker_types__and && localFilter.planeswalker_types__and.length > 0) {
      summary.push(`Planeswalker Types (AND): ${mapCodesToLabels(localFilter.planeswalker_types__and, planeswalkerTypes)}`);
    }
    if (localFilter.planeswalker_types__only && localFilter.planeswalker_types__only.length > 0) {
      summary.push(`Planeswalker Types (ONLY): ${mapCodesToLabels(localFilter.planeswalker_types__only, planeswalkerTypes)}`);
    }

    // Creature Types
    if (localFilter.creature_types__or && localFilter.creature_types__or.length > 0) {
      summary.push(`Creature Types (OR): ${mapCodesToLabels(localFilter.creature_types__or, creatureTypes)}`);
    }
    if (localFilter.creature_types__and && localFilter.creature_types__and.length > 0) {
      summary.push(`Creature Types (AND): ${mapCodesToLabels(localFilter.creature_types__and, creatureTypes)}`);
    }
    if (localFilter.creature_types__only && localFilter.creature_types__only.length > 0) {
      summary.push(`Creature Types (ONLY): ${mapCodesToLabels(localFilter.creature_types__only, creatureTypes)}`);
    }

    // Land Types
    if (localFilter.land_types__or && localFilter.land_types__or.length > 0) {
      summary.push(`Land Types (OR): ${mapCodesToLabels(localFilter.land_types__or, landTypes)}`);
    }
    if (localFilter.land_types__and && localFilter.land_types__and.length > 0) {
      summary.push(`Land Types (AND): ${mapCodesToLabels(localFilter.land_types__and, landTypes)}`);
    }
    if (localFilter.land_types__only && localFilter.land_types__only.length > 0) {
      summary.push(`Land Types (ONLY): ${mapCodesToLabels(localFilter.land_types__only, landTypes)}`);
    }

    // Oracle Text
    if (localFilter.oracle_text) { // Replaced 'oracleText' with 'localFilter.oracle_text'
      summary.push(`Oracle Text: ${localFilter.oracle_text}`);
    }

    // Symbols
    if (selectedSymbols.length > 0) {
      summary.push(`Symbols: ${selectedSymbols.join(", ")}`);
    }

    // Join the summary array into a string
    const joinedSummary = summary.join(", ");

    setSearchSummary(joinedSummary);
  }, [
    localFilter,
    combinedManaCost,
    selectedColors,
    selectedSymbols,
    sets,
    rarities,
    keywords,
    legalities,
    cardTypes,
    superTypes,
    planeswalkerTypes,
    creatureTypes,
    landTypes,
  ]);

  // Handle input field changes
  const handleInputChange = (name, value) => {
    setLocalFilter((prev) => ({ ...prev, [name]: value }));
  };

  // Handle conditional changes for numeric fields
  const handleConditionChange = (name, condition, value) => {
    const cleanedValue = value.replace(/[^\d]/g, "");

    if (cleanedValue === "") {
      handleInputChange(name, "");
      handleInputChange(`${name}__lt`, "");
      handleInputChange(`${name}__gt`, "");
      return;
    }

    if (condition === "=") {
      handleInputChange(name, cleanedValue);
      handleInputChange(`${name}__lt`, "");
      handleInputChange(`${name}__gt`, "");
    } else if (condition === "<") {
      handleInputChange(`${name}__lt`, cleanedValue);
      handleInputChange(name, "");
      handleInputChange(`${name}__gt`, "");
    } else if (condition === ">") {
      handleInputChange(`${name}__gt`, cleanedValue);
      handleInputChange(name, "");
      handleInputChange(`${name}__lt`, "");
    }
  };

  // Handle Oracle Text search
  const handleOracleTextChange = (value) => {
    setLocalFilter((prevFilter) => ({ ...prevFilter, oracle_text: value }));
  };

  // Handle Color change (for Contains Mode)
  const handleColorChange = (value) => {
    const updatedColors = selectedColors.includes(value)
      ? selectedColors.filter((color) => color !== value)
      : [...selectedColors, value];

    setSelectedColors(updatedColors);
  };

  // Handle Symbol change
  const handleSymbolChange = (value) => { // Defined handleSymbolChange
    const updatedSymbols = selectedSymbols.includes(value)
      ? selectedSymbols.filter((symbol) => symbol !== value)
      : [...selectedSymbols, value];

    setSelectedSymbols(updatedSymbols);
  };

  // Handle adding mana symbol and quantity to the combined mana cost string (for Exact Mode)
  const handleAddManaCost = () => {
    if (selectedManaSymbol && manaQuantity > 0) {
      setManaCounts((prevManaCounts) => {
        const updatedManaCounts = { ...prevManaCounts };

        updatedManaCounts[selectedManaSymbol] = (updatedManaCounts[selectedManaSymbol] || 0) + manaQuantity;

        // Order the mana symbols for consistent rendering
        const orderedSymbols = [
          "{X}",
          "{1}",
          ...colors.map((color) => color.value),
        ];

        let newManaString = "";

        orderedSymbols.forEach((symbol) => {
          const qty = updatedManaCounts[symbol];
          if (qty) {
            if (symbol === "{1}") {
              newManaString += `{${qty}}`;
            } else {
              newManaString += `${symbol}`.repeat(qty);
            }
          }
        });

        setCombinedManaCost(newManaString);
        return updatedManaCounts;
      });
    }
  };

  // Handle search button click
  const handleSearch = () => {
    // Prepare the mana cost string for the search
    let searchManaCost = "";

    if (colorMode === "exact") {
      searchManaCost = combinedManaCost;
    } else if (colorMode === "contains" && selectedColors.length > 0) {
      searchManaCost = selectedColors.join(",");
    }

    // Prepare the symbols string for the search
    const symbolsString = selectedSymbols.join(",");

    // Construct the filter with mana cost, color mode, and symbols
    const updatedFilter = {
      ...localFilter,
      mana_cost: searchManaCost,
      color_mode: colorMode,
      symbols: symbolsString,
    };

    // Filter out empty or undefined values
    const cleanedFilter = Object.fromEntries(
      Object.entries(updatedFilter).filter(
        ([, value]) =>
          value !== "" &&
          value !== undefined &&
          value !== null &&
          !(Array.isArray(value) && value.length === 0)
      )
    );

    // Update the filter state with the cleaned values
    setFilter(cleanedFilter);
  };

  // Handle clear filters button click
  const handleClearFilters = () => {
    const resetFilter = {
      name: "",
      set_code: [],
      cmc: "",
      cmc__lt: "",
      cmc__gt: "",
      colors: [],
      color_mode: "contains",
      card_types__or: [],
      card_types__and: [],
      card_types__only: [],
      rarity__or: [],
      rarity__and: [],
      rarity__only: [],
      legalities__or: [],
      legalities__and: [],
      legalities__only: [],
      symbols__or: [],
      symbols__and: [],
      symbols__only: [],
      startDate: "",
      endDate: "",
      power: "",
      power__lt: "",
      power__gt: "",
      toughness: "",
      toughness__lt: "",
      toughness__gt: "",
      keywords__or: [],
      keywords__and: [],
      keywords__only: [],
      super_types__or: [],
      super_types__and: [],
      super_types__only: [],
      planeswalker_types__or: [],
      planeswalker_types__and: [],
      planeswalker_types__only: [],
      creature_types__or: [],
      creature_types__and: [],
      creature_types__only: [],
      land_types__or: [],
      land_types__and: [],
      land_types__only: [],
      oracle_text: "",
    };

    setLocalFilter(resetFilter);
    setManaCostCondition("=");
    setPowerCondition("=");
    setToughnessCondition("=");
    setCombinedManaCost("");
    setSelectedManaSymbol("");
    setManaQuantity(1);
    setLegalitiesCondition("|");
    setRarityCondition("|");
    setCardTypeCondition("|");
    setSuperTypeCondition("|");
    setPlaneswalkerTypeCondition("|");
    setCreatureTypeCondition("|");
    setLandTypeCondition("|");
    setKeywordsCondition("|");
    setSelectedColors([]);
    setSelectedSymbols([]);
    setFilter(resetFilter);
    setManaCounts({});
    setShowMoreColors(false);
    setShowMoreFilters(false);
    setShowMoreSymbols(false);
    setSearchSummary("");
  };

  // Dropdown styling based on theme
  const dropdownClass = theme === "dark" ? "bg-gray-700 text-white" : "bg-white text-gray-700";

  return (
    <div className="filter-component space-y-4">
      {/* Grid of filters */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {/* Card Name Autocomplete */}
        <InputField
          label="Card Name"
          name="name"
          showCardDetails={true}
          value={localFilter.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
          options={cardNames}
          isAutocomplete={true}
          placeholder="Search for card name..."
        />

        {/* Set Name Dropdown */}
        <InputField
          label="Set Name"
          name="set_code"
          value={localFilter.set_code}
          type="multi-select"
          onChange={(e) => handleInputChange("set_code", e.target.value)}
          options={[{ value: "", label: "Any" }, ...sets]}
          isSearchable
          isMulti
        />

        {/* Mana Cost */}
        <div className="flex mb-4">
          <select
            value={manaCostCondition}
            onChange={(e) => setManaCostCondition(e.target.value)}
            className={`mr-2 ${dropdownClass} border rounded p-2`}
          >
            <option value="=">=</option>
            <option value="<">&lt;</option>
            <option value=">">&gt;</option>
          </select>
          <InputField
            label="Mana Cost"
            name="cmc"
            value={manaCostCondition === "=" ? localFilter.cmc : manaCostCondition === "<" ? localFilter.cmc__lt : localFilter.cmc__gt}
            onChange={(e) => handleConditionChange("cmc", manaCostCondition, e.target.value)}
            className="w-full"
          />
        </div>

        {/* Power and Toughness */}
        {["power", "toughness"].map((field) => (
          <div key={field} className="flex mb-4">
            <select
              value={field === "power" ? powerCondition : toughnessCondition}
              onChange={(e) => (field === "power" ? setPowerCondition(e.target.value) : setToughnessCondition(e.target.value))}
              className={`mr-2 ${dropdownClass} border rounded p-2`}
            >
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value=">">&gt;</option>
            </select>
            <InputField
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              name={field}
              value={
                field === "power"
                  ? powerCondition === "="
                    ? localFilter.power
                    : powerCondition === "<"
                      ? localFilter.power__lt
                      : localFilter.power__gt
                  : toughnessCondition === "="
                    ? localFilter.toughness
                    : toughnessCondition === "<"
                      ? localFilter.toughness__lt
                      : localFilter.toughness__gt
              }
              onChange={(e) => handleConditionChange(field, field === "power" ? powerCondition : toughnessCondition, e.target.value)}
              className="w-full"
            />
          </div>
        ))}

        {/* Rarity Multi-select */}
        <InputField
          label="Rarity"
          name="rarity"
          value={getSelectedOptions(
            rarityCondition,
            rarities, // Assuming rarities is your options array
            rarityCondition === "="
              ? localFilter.rarity__only
              : rarityCondition === "&"
                ? localFilter.rarity__and
                : localFilter.rarity__or
          )} onChange={(e) => {
            const newValue = e.target.value;
            setLocalFilter((prevFilter) => {
              let updatedFilter = { ...prevFilter };
              if (rarityCondition === "|") {
                updatedFilter.rarity__or = newValue;
              } else if (rarityCondition === "&") {
                updatedFilter.rarity__and = newValue;
              } else {
                updatedFilter.rarity__only = newValue;
              }
              return updatedFilter;
            });
          }}
          type="multi-select"
          options={rarities}
          className="w-full"
          isSearchable
          isMulti
        />

        {/* Keywords Multi-select */}
        <div className="flex mb-4">
          <select
            value={keywordsCondition}
            onChange={(e) => setKeywordsCondition(e.target.value)}
            className={`mr-2 ${dropdownClass} border rounded p-2`}
          >
            <option value="|">|</option>
            <option value="&">&</option>
            <option value="=">=</option>
          </select>
          <InputField
            label="Keywords"
            name="keywords"
            value={getSelectedOptions(
              keywordsCondition,
              keywords,
              keywordsCondition === "="
                ? localFilter.keywords__only
                : keywordsCondition === "&"
                  ? localFilter.keywords__and
                  : localFilter.keywords__or
            )}
            onChange={(e) => {
              const newValue = e.target.value;
              setLocalFilter((prevFilter) => {
                let updatedFilter = { ...prevFilter };
                if (keywordsCondition === "|") {
                  updatedFilter.keywords__or = newValue;
                } else if (keywordsCondition === "&") {
                  updatedFilter.keywords__and = newValue;
                } else {
                  updatedFilter.keywords__only = newValue;
                }
                return updatedFilter;
              });
            }}
            type="multi-select"
            options={keywords}
            className="w-full"
            isMulti
            isSearchable
          />
        </div>

        {/* Oracle Text */}
        <InputField
          label="Oracle Text"
          name="oracle_text"
          value={oracleText}
          onChange={(e) => handleOracleTextChange(e.target.value)}
          placeholder="Search by card text..."
        />

        {/* Legalities Multi-select */}
        <div className="flex mb-4">
          <select
            value={legalitiesCondition}
            onChange={(e) => setLegalitiesCondition(e.target.value)}
            className={`mr-2 ${dropdownClass} border rounded p-2`}
          >
            <option value="|">|</option>
            <option value="&">&</option>
          </select>
          <InputField
            label="Legalities"
            name="legalities"
            value={legalitiesCondition === "=" ? localFilter.legalities__only : legalitiesCondition === "&" ? localFilter.legalities__and : localFilter.legalities__or}
            onChange={(e) => {
              const newValue = e.target.value;
              setLocalFilter((prevFilter) => {
                let updatedFilter = { ...prevFilter };
                if (legalitiesCondition === "|") {
                  updatedFilter.legalities__or = newValue;
                } else if (legalitiesCondition === "&") {
                  updatedFilter.legalities__and = newValue;
                } else {
                  updatedFilter.legalities__only = newValue;
                }
                return updatedFilter;
              });
            }}
            type="multi-select"
            options={legalities}
            className="w-full"
            isMulti
            isSearchable
          />
        </div>

        {/* Card Type Multi-select */}
        {showMoreFilters && (
          <div className="flex mb-4">
            <select
              value={cardTypeCondition}
              onChange={(e) => setCardTypeCondition(e.target.value)}
              className={`mr-2 ${dropdownClass} border rounded p-2`}
            >
              <option value="|">|</option>
              <option value="&">&</option>
            </select>
            <InputField
              label="Card Types"
              name="card_types"
              type="select"
              options={cardTypes}
              value={
                cardTypeCondition === "="
                  ? localFilter.card_types__only
                  : cardTypeCondition === "&"
                    ? localFilter.card_types__and
                    : localFilter.card_types__or
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setLocalFilter((prevFilter) => {
                  let updatedFilter = { ...prevFilter };
                  if (cardTypeCondition === "|") {
                    updatedFilter.card_types__or = newValue;
                  } else if (cardTypeCondition === "&") {
                    updatedFilter.card_types__and = newValue;
                  } else {
                    updatedFilter.card_types__only = newValue;
                  }
                  return updatedFilter;
                });
              }}
              className="w-full"
              isSearchable
              isMulti
            />
          </div>
        )}


        {/* Super Type Multi-select */}
        {showMoreFilters && (
          <div className="flex mb-4">
            <select
              value={superTypeCondition}
              onChange={(e) => setSuperTypeCondition(e.target.value)}
              className={`mr-2 ${dropdownClass} border rounded p-2`}
            >
              <option value="|">|</option>
              <option value="&">&</option>
            </select>
            <InputField
              label="Super Types"
              name="super_types"
              type="select"
              options={superTypes}
              value={
                superTypeCondition === "="
                  ? localFilter.super_types__only
                  : superTypeCondition === "&"
                    ? localFilter.super_types__and
                    : localFilter.super_types__or
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setLocalFilter((prevFilter) => {
                  let updatedFilter = { ...prevFilter };
                  if (superTypeCondition === "|") {
                    updatedFilter.super_types__or = newValue;
                  } else if (superTypeCondition === "&") {
                    updatedFilter.super_types__and = newValue;
                  } else {
                    updatedFilter.super_types__only = newValue;
                  }
                  return updatedFilter;
                });
              }}
              className="w-full"
              isSearchable
              isMulti
            />
          </div>
        )}

        {/* Planeswalker Types */}
        {showMoreFilters && (
          <div className="flex mb-4">
            <select
              value={planeswalkerTypeCondition}
              onChange={(e) => setPlaneswalkerTypeCondition(e.target.value)}
              className={`mr-2 ${dropdownClass} border rounded p-2`}
            >
              <option value="|">|</option>
              <option value="&">&</option>
            </select>
            <InputField
              label="Planeswalker Types"
              name="planeswalker_types"
              type="select"
              options={planeswalkerTypes}
              value={
                planeswalkerTypeCondition === "="
                  ? localFilter.planeswalker_types__only
                  : planeswalkerTypeCondition === "&"
                    ? localFilter.planeswalker_types__and
                    : localFilter.planeswalker_types__or
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setLocalFilter((prevFilter) => {
                  let updatedFilter = { ...prevFilter };
                  if (planeswalkerTypeCondition === "|") {
                    updatedFilter.planeswalker_types__or = newValue;
                  } else if (planeswalkerTypeCondition === "&") {
                    updatedFilter.planeswalker_types__and = newValue;
                  } else {
                    updatedFilter.planeswalker_types__only = newValue;
                  }
                  return updatedFilter;
                });
              }}
              className="w-full"
              isSearchable
              isMulti
            />
          </div>
        )}

        {/* Creature Type Multi-select */}
        {showMoreFilters && (
          <div className="flex mb-4">
            <select
              value={creatureTypeCondition}
              onChange={(e) => setCreatureTypeCondition(e.target.value)}
              className={`mr-2 ${dropdownClass} border rounded p-2`}
            >
              <option value="|">|</option>
              <option value="&">&</option>
              <option value="=">=</option>
            </select>
            <InputField
              label="Creature Types"
              name="creature_types"
              type="select"
              options={creatureTypes}
              value={
                creatureTypeCondition === "="
                  ? localFilter.creature_types__only
                  : creatureTypeCondition === "&"
                    ? localFilter.creature_types__and
                    : localFilter.creature_types__or
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setLocalFilter((prevFilter) => {
                  let updatedFilter = { ...prevFilter };
                  if (creatureTypeCondition === "|") {
                    updatedFilter.creature_types__or = newValue;
                  } else if (creatureTypeCondition === "&") {
                    updatedFilter.creature_types__and = newValue;
                  } else {
                    updatedFilter.creature_types__only = newValue;
                  }
                  return updatedFilter;
                });
              }}
              className="w-full"
              isSearchable
              isMulti
            />
          </div>
        )}

        {/* Land Type Multi-select */}
        {showMoreFilters && (
          <div className="flex mb-4">
            <select
              value={landTypeCondition}
              onChange={(e) => setLandTypeCondition(e.target.value)}
              className={`mr-2 ${dropdownClass} border rounded p-2`}
            >
              <option value="|">|</option>
              <option value="&">&</option>
            </select>
            <InputField
              label="Land Types"
              name="land_types"
              type="select"
              options={landTypes}
              value={
                landTypeCondition === "="
                  ? localFilter.land_types__only
                  : landTypeCondition === "&"
                    ? localFilter.land_types__and
                    : localFilter.land_types__or
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setLocalFilter((prevFilter) => {
                  let updatedFilter = { ...prevFilter };
                  if (landTypeCondition === "|") {
                    updatedFilter.land_types__or = newValue;
                  } else if (landTypeCondition === "&") {
                    updatedFilter.land_types__and = newValue;
                  } else {
                    updatedFilter.land_types__only = newValue;
                  }
                  return updatedFilter;
                });
              }}
              className="w-full"
              isSearchable
              isMulti
            />
          </div>
        )}
      </div>
      <div>
        {showMoreFilters && (
          <button
            onClick={() => setShowMoreFilters(false)}
            className="text-blue-500 hover:underline mt-2"
          >
            Show less filters
          </button>
        )}
        {!showMoreFilters && (
          <button
            onClick={() => setShowMoreFilters(true)}
            className="text-blue-500 hover:underline mt-2"

          >
            Show more filters...
          </button>
        )}
      </div>
      {/* Mana Cost Filter Mode */}
      <div className="mt-4">
        <label className={`block text-sm font-medium ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}>
          Mana Cost Filter Mode
        </label>
        <select
          value={colorMode}
          onChange={(e) => setColorMode(e.target.value)}
          className={`mb-4 ${dropdownClass} border rounded p-2`}
        >
          <option value="contains">Contains Colors</option>
          <option value="exact">Exact Mana Cost</option>
        </select>

        {colorMode === "exact" ? (
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Exact Mana Cost</label>
            <div className="flex items-center space-x-2">
              <InputField
                label="Mana Cost"
                name="mana_cost"
                type="select"
                options={[{ label: renderMana("{X}"), value: "{X}" }, { label: renderMana("{1}"), value: "{1}" }, ...colors.map((color) => ({ label: renderMana(color.value), value: color.value }))]}
                value={selectedManaSymbol}
                onChange={(e) => setSelectedManaSymbol(e.target.value)}
              />
              <InputField
                label="Quantity"
                name="quantity"
                type="number"
                value={manaQuantity}
                onChange={(e) => setManaQuantity(e.target.value)}
              />
              <Button onClick={handleAddManaCost}>Add Mana Cost</Button>
            </div>
            <p className="mt-2">Current Mana Cost: <strong>{renderMana(combinedManaCost)}</strong></p>
          </div>
        ) : (
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Contains Colors</label>
            <div className="flex flex-wrap gap-2">
              {(showMoreColors ? colors : limitedColors).map((color) => (
                <div key={color.value} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`color-${color.label}`}
                    value={color.value}
                    checked={selectedColors.includes(color.value)}
                    onChange={() => handleColorChange(color.value)}
                    className="mr-2"
                  />
                  <label
                    htmlFor={`color-${color.value}`}
                    className={`text-sm ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}
                  >
                    {renderMana(color.label)} {/* Show the mana symbol */}
                  </label>
                </div>
              ))}
            </div>
            {!showMoreColors && (
              <button
                onClick={() => setShowMoreColors(true)}
                className="text-blue-500 hover:underline mt-2"
              >
                More colors...
              </button>
            )}
            {showMoreColors && (
              <button
                onClick={() => setShowMoreColors(false)}
                className="text-blue-500 hover:underline mt-2"
              >
                Show fewer colors
              </button>
            )}
          </div>
        )}
        {showMoreSymbols && (
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Symbols</label>
            <div className="flex flex-wrap gap-2">
              {symbols.map((symbol) => (
                <div key={symbol.code} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`symbol-${symbol.code}`}
                    value={symbol.code}
                    checked={selectedSymbols.includes(symbol.code)}
                    onChange={() => handleColorChange(symbol.code)}
                    className="mr-2"
                  />
                  <label
                    htmlFor={`symbol-${symbol.code}`}
                    className={`text-sm ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}
                  >
                    {renderMana(symbol.code, theme)} {/* Ensure correct symbol code is passed */}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* Symbol Selection */}
      <div className="mt-4">
        <label className={`block text-sm font-medium mb-2 ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}>
          Symbols
        </label>
        <div className="flex flex-wrap gap-2">
          {(showMoreSymbols ? symbols : limitedSymbols).map((symbol) => (
            <div key={symbol.value} className="flex items-center">
              <input
                type="checkbox"
                id={`symbol-${symbol.label}`}
                value={symbol.value}
                checked={selectedSymbols.includes(symbol.value)}
                onChange={() => handleColorChange(symbol.value)}
                className="mr-2"
              />
              <label
                htmlFor={`symbol-${symbol.value}`}
                className={`text-sm ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}
              >
                {renderMana(symbol.label, theme)}
              </label>
            </div>
          ))}
        </div>

      </div>

      {/* Show more/fewer symbols toggle */}
      {!showMoreSymbols && (
        <button onClick={() => setShowMoreSymbols(true)} className="text-blue-500 hover:underline mt-2">
          Show more symbols...
        </button>
      )}
      {showMoreSymbols && (
        <button onClick={() => setShowMoreSymbols(false)} className="text-blue-500 hover:underline mt-2">
          Show fewer symbols
        </button>
      )}
      {/* Buttons */}
      <div className="flex space-x-4 mt-4">
        <Button onClick={handleSearch}>Search</Button>
        <Button onClick={handleClearFilters} type="button" variant="secondary">
          Clear All Filters
        </Button>
      </div>
      <div>
        {/* Search Parameters Display */}
        <div className="flex items-center bg-black text-white px-4 py-2 mt-4">
          <span>Search Parameters: {renderMana(searchSummary, theme)}</span>
        </div>
      </div>
    </div>
  );
};

export default Filter;
