import React, { useState, useContext, useRef, useEffect } from "react";
import { ThemeContext } from "../layouts/ThemeContext";

const Button = ({
  children,
  onClick,
  className,
  icon,
  dropdownItems,
  username,
  userImage,
  customDropdownContent, // New prop for custom dropdown content
}) => {
  const { theme } = useContext(ThemeContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (dropdownItems || customDropdownContent) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={handleClick}
        className={`flex items-center px-4 py-2 rounded font-semibold transition duration-300 ${className} ${theme === "dark"
            ? "bg-green-600 text-white hover:bg-green-700"
            : "bg-green-500 text-black hover:bg-green-600"
          }`}
      >
        {userImage && (
          <img
            src={userImage}
            alt="User"
            className="w-6 h-6 rounded-full mr-2"
          />
        )}
        {username && <span className="mr-2">{username}</span>}
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </button>
      {isDropdownOpen && (dropdownItems || customDropdownContent) && (
        <div
          className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg z-20 ${theme === "dark" ? "bg-gray-800" : "bg-white"
            }`}
        >
          <div className="py-1">
            {customDropdownContent ? (
              customDropdownContent
            ) : (
              dropdownItems.map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  onClick={item.onClick}
                  className={`block px-4 py-2 text-sm ${theme === "dark"
                      ? "text-gray-300 hover:bg-gray-700 hover:text-white"
                      : "text-gray-800 hover:bg-gray-100"
                    }`}
                >
                  {item.label}
                </a>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Button;
