import React, { useState, useContext } from "react";
import Card from "../components/misc/Card";
import BaseLayout from "../components/layouts/BaseLayout";
import { ThemeContext } from "../components/layouts/ThemeContext";
import Button from "../components/forms/Button";
import InputField from "../components/forms/InputField";

const ForgotPasswordPage = () => {
  const { theme } = useContext(ThemeContext);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/authentication/forgot-password/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("If an account with that email exists, you will receive a password reset email shortly.");
        setError("");
      } else {
        const result = await response.json();
        setError(result.error || "Something went wrong. Please try again.");
        setMessage("");
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
      setMessage("");
    }
  };

  return (
    <BaseLayout>
      <section className="flex justify-center items-center h-screen">
        <Card title="Forgot Password" titleSize="text-3xl" imageSrc="/logo.png">
          <div className={`w-full ${theme === "dark" ? "text-gray-300" : "text-gray-600"}`}>
            <form className="space-y-6" onSubmit={handleForgotPassword}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <InputField
                    label="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              {error && <p className="text-red-500 text-center">{error}</p>}
              {message && <p className="text-green-500 text-center">{message}</p>}
              <Button type="submit" className="w-full">
                Send Password Reset Email
              </Button>
            </form>
          </div>
        </Card>
      </section>
    </BaseLayout>
  );
};

export default ForgotPasswordPage;
