import React, { useContext } from 'react';
import Card from '../components/misc/Card';
import Button from '../components/forms/Button';
import BaseLayout from '../components/layouts/BaseLayout';
import { ThemeContext } from '../components/layouts/ThemeContext';

function About() {
    const { theme } = useContext(ThemeContext);
    const barColor = theme === "dark" ? "bg-[#003268]" : "bg-[#FF6F61]";

    return (
        <BaseLayout>
            <section className="text-center py-20">
                <Card
                    title="About CardRealm"
                    titleSize="text-3xl"
                >
                    <h2 className="text-2xl mb-6">Empowering Magic: The Gathering Players Everywhere</h2>
                    <div className={`h-1 w-3/4 mx-auto mb-8 ${barColor}`} />

                    <h3 className="text-xl mb-4">Our Mission</h3>
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <p className="text-lg mb-10 px-8">
                        At CardRealm, our mission is to empower Magic: The Gathering (MTG) players by offering comprehensive tools that simplify and enhance their gaming experience. We provide an intuitive platform for managing collections, building competitive decks, and connecting with a vibrant community of MTG enthusiasts. Our goal is to support players at every level, from casual collectors to professional competitors, by delivering innovative features and exceptional user support.
                    </p>

                    <h3 className="text-xl mb-4">Our Vision</h3>
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <p className="text-lg mb-10 px-8">
                        We envision a world where every MTG player, from casual collectors to professional competitors, has access to the tools and resources they need to fully enjoy and excel in the game. By continuously improving and expanding our platform, we aim to become the go-to resource for all things MTG.
                    </p>

                    <h3 className="text-xl mb-4">Meet the Founder</h3>
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <p className="text-lg mb-10 px-8">
                        <strong>Simon Lefebvre - Founder & CEO</strong><br />
                        <div className="flex justify-center my-6">
                            <img className="inline w-72 h-72 rounded-full border-2 border-green-500" src="/static/simon.jpg" alt="Simon Lefebvre" />
                        </div>
                        Simon has been an avid MTG player for over a decade. His passion for the game, combined with his expertise in software development, drives the innovative features and user-friendly design of CardRealm. As the sole person behind CardRealm, Simon is dedicated to ensuring the platform meets the diverse needs of the MTG community.
                    </p>

                    <h3 className="text-xl mb-4">Join the CardRealm Community</h3>
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <p className="text-lg mb-10 px-8">
                        We invite you to join the CardRealm community and be a part of our growing family. Whether you're a casual player or a competitive pro, CardRealm is designed to enhance your MTG experience and help you connect with fellow enthusiasts. Together, let's take your MTG game to the next level.
                    </p>

                    <div className="flex justify-center mb-10">
                        <Button onClick={() => console.log("Join Now clicked!")}>
                            Join Now
                        </Button>
                    </div>

                    <h3 className="text-xl mt-10 mb-4">Get in Touch</h3>
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <p className="text-lg mb-8 px-8">
                        Have questions or feedback? We'd love to hear from you! Reach out to us at <a href="mailto:simon.lefebvre@cardrealm.io" className="text-blue-500 hover:underline">simon.lefebvre@cardrealm.io</a> or connect with us on our social media channels.
                    </p>
                </Card>
            </section>
        </BaseLayout>
    );
}

export default About;
