import React, { useEffect, useState, useContext } from "react";
import useAxiosInstance from "../services/axiosInstance";
import Card from "../components/misc/Card";
import BaseLayout from "../components/layouts/BaseLayout";
import { ThemeContext } from "../components/layouts/ThemeContext";
import Loading from "../components/misc/Loader";
import Button from "../components/forms/Button";
import InputField from "../components/forms/InputField";
import { FiEdit2, FiSave, FiX } from "react-icons/fi"; // Icons for better UX

const Profile = () => {
  const axiosInstance = useAxiosInstance(); // Use the custom axios instance
  const [profile, setProfile] = useState(null);
  const [formData, setFormData] = useState({});
  const [avatarFile, setAvatarFile] = useState(null); // State for avatar file
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    axiosInstance
      .get("/api/authentication/profile/")
      .then((response) => {
        const userData = response.data;
        setProfile(userData);
        setFormData({
          email: userData.email,
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone_number: userData.phone_number,
          location: userData.profile.location || "",
          birth_date: userData.profile.birth_date || "",
          bio: userData.profile.bio || "",
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the profile!", error);
      });
  }, [axiosInstance]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setAvatarFile(e.target.files[0]); // Set the selected file
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("first_name", formData.first_name);
    formDataToSend.append("last_name", formData.last_name);
    formDataToSend.append("phone_number", formData.phone_number);
    formDataToSend.append("profile.location", formData.location);
    formDataToSend.append("profile.birth_date", formData.birth_date);
    formDataToSend.append("profile.bio", formData.bio);

    if (avatarFile) {
      formDataToSend.append("profile.avatar", avatarFile); // Append the avatar file
    }

    axiosInstance
      .put("/api/authentication/profile/", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccess("Profile updated successfully!");
        setProfile(response.data);
        setEditMode(false); // Exit edit mode on success
      })
      .catch((error) => {
        console.error("There was an error updating the profile!", error.response.data);
        setError("Error updating profile.");
      });
  };

  if (!profile) {
    return <Loading />;
  }

  return (
    <BaseLayout>
      <section className="flex justify-center items-center min-h-screen p-4">
        <Card
          title={`${profile.username}'s Profile`}
          titleSize="text-3xl"
          className={`w-full max-w-4xl ${theme === "dark" ? "text-gray-300" : "text-gray-600"}`}
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="profile-info space-y-4">
              <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
              {profile.profile?.avatar && (
                <div className="flex items-center space-x-4">
                  <span className="text-gray-500">Avatar:</span>
                  <img src={profile.profile.avatar} alt="Avatar" className="w-32 h-32 object-cover rounded-full" />
                </div>
              )}
              {[
                { label: "Username", value: profile.username },
                { label: "Email", value: profile.email },
                { label: "First Name", value: profile.first_name },
                { label: "Last Name", value: profile.last_name },
                { label: "Phone Number", value: profile.phone_number || "N/A" },
                { label: "Location", value: profile.profile?.location || "N/A" },
                { label: "Birth Date", value: profile.profile?.birth_date || "N/A" },
                { label: "Bio", value: profile.profile?.bio || "N/A" },
              ].map((item) => (
                <div key={item.label} className="flex flex-col sm:flex-row items-start sm:items-center">
                  <span className="w-full sm:w-1/3 text-gray-500">{item.label}:</span>
                  <span className="w-full sm:w-2/3">{item.value}</span>
                </div>
              ))}

              <Button type="button" onClick={() => setEditMode(true)} className="w-full mt-4 flex items-center justify-center">
                <FiEdit2 className="mr-2" /> Edit Profile
              </Button>
            </div>

            {editMode && (
              <div className="edit-profile space-y-6">
                <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                  {[
                    { label: "Email", type: "email", name: "email", value: formData.email },
                    { label: "First Name", type: "text", name: "first_name", value: formData.first_name },
                    { label: "Last Name", type: "text", name: "last_name", value: formData.last_name },
                    { label: "Phone Number", type: "text", name: "phone_number", value: formData.phone_number },
                    { label: "Location", type: "text", name: "location", value: formData.location },
                    { label: "Birth Date", type: "date", name: "birth_date", value: formData.birth_date },
                    { label: "Bio", type: "textarea", name: "bio", value: formData.bio },
                  ].map((item) => (
                    <InputField
                      key={item.name}
                      label={item.label}
                      type={item.type}
                      name={item.name}
                      value={item.value}
                      onChange={handleChange}
                      required={item.name !== "phone_number"} // Make phone number optional
                    />
                  ))}
                  <div className="space-y-2">
                    <label htmlFor="avatar" className="block text-sm font-medium text-gray-700">
                      Avatar
                    </label>
                    <input
                      type="file"
                      id="avatar"
                      name="avatar"
                      onChange={handleFileChange}
                      accept="image/*"
                      className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                    />
                  </div>
                  {error && <p className="text-red-500 text-center">{error}</p>}
                  {success && <p className="text-green-500 text-center">{success}</p>}
                  <div className="flex justify-between">
                    <Button type="submit" className="flex items-center justify-center">
                      <FiSave className="mr-2" /> Save Changes
                    </Button>
                    <Button type="button" onClick={() => setEditMode(false)} className="flex items-center justify-center">
                      <FiX className="mr-2" /> Cancel
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </Card>
      </section>
    </BaseLayout>
  );
};

export default Profile;
