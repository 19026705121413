import React, { useState, useContext, useEffect } from "react";
import Card from "../components/misc/Card";
import BaseLayout from "../components/layouts/BaseLayout";
import { ThemeContext } from "../components/layouts/ThemeContext";
import Button from "../components/forms/Button";
import InputField from "../components/forms/InputField";
import { useParams, useNavigate } from "react-router-dom";
import useAxiosInstance from "../services/axiosInstance";

const ResetPasswordPage = () => {
  const { theme } = useContext(ThemeContext);
  const { userId, token } = useParams();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (!axiosInstance) {
      setError("Axios instance is not ready");
    }
  }, [axiosInstance]);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (!axiosInstance) {
      setError("Axios instance is not ready");
      return;
    }

    try {
      console.log("Sending password reset request...");
      const response = await axiosInstance.post("/api/authentication/reset-password/", {
        uid: userId,
        token,
        new_password: password,
      });
      console.log("Password reset successful:", response.data.message);
      setSuccess(response.data.message);
      setError("");
      // Redirect to login page after successful password reset
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      console.error("Error resetting password:", error);
      setError(error.response?.data?.error || "An error occurred");
      setSuccess("");
    }
  };

  return (
    <BaseLayout>
      <section className="flex justify-center items-center h-screen">
        <Card title="Reset Password" titleSize="text-3xl" imageSrc="/logo.png">
          <div className={`w-full ${theme === "dark" ? "text-gray-300" : "text-gray-600"}`}>
            <form className="space-y-6" onSubmit={handleResetPassword}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <InputField label="New Password" type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <div>
                  <InputField label="Confirm Password" type="password" id="confirm-password" name="confirm-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </div>
              </div>
              {error && <p className="text-red-500 text-center">{error}</p>}
              {success && <p className="text-green-500 text-center">{success}</p>}
              <Button type="submit" className="w-full">Reset Password</Button>
            </form>
          </div>
        </Card>
      </section>
    </BaseLayout>
  );
};

export default ResetPasswordPage;
