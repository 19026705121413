import React, { useContext, useRef, useEffect, useState } from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import Card from "../misc/Card";
import { ThemeContext } from "../layouts/ThemeContext";

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
);

const Chart = ({ type, data, options, title, titleSize = "text-2xl" }) => {
  const { theme } = useContext(ThemeContext);
  const chartRef = useRef(null);

  // Update chart size on window resize
  const [chartSize, setChartSize] = useState({ height: 400, width: 600 });

  const handleResize = () => {
    if (chartRef.current) {
      setChartSize({
        height: chartRef.current.clientHeight,
        width: chartRef.current.clientWidth,
      });
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Ensure options and plugins are defined
  const chartOptions = {
    ...options,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      ...options?.plugins,
      title: {
        display: true,
        text: title || "Chart Title",
        font: {
          size: parseInt(titleSize, 10), // Convert titleSize to integer
        },
      },
    },
  };

  const renderChart = () => {
    switch (type) {
      case "line":
        return <Line data={data} options={chartOptions} />;
      case "bar":
        return <Bar data={data} options={chartOptions} />;
      case "pie":
        return <Pie data={data} options={chartOptions} />;
      default:
        return <div>Unsupported chart type</div>;
    }
  };

  return (
    <Card title={title} className="w-full" titleSize={titleSize}>
      <div ref={chartRef} className="w-full h-full">
        {renderChart()}
      </div>
    </Card>
  );
};

export default Chart;
