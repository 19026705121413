import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getCsrfToken } from "./csrf"; // Import the CSRF utility function

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = Cookies.get("__session");

      if (token) {
        try {
          await getCsrfToken(); // Ensure the CSRF token is fetched

          const response = await fetch("/api/authentication/verify-token/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
              "X-CSRFToken": Cookies.get("csrftoken"), // Include the CSRF token
            },
          });

          if (response.ok) {
            setIsAuthenticated(true);
            const userInfoResponse = await fetch("/api/authentication/user-info/", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              },
            });

            if (userInfoResponse.ok) {
              const userData = await userInfoResponse.json();
              setUser(userData);
            } else {
              setIsAuthenticated(false);
              setUser(null);
            }
          } else {
            setIsAuthenticated(false);
            setUser(null);
          }
        } catch (error) {
          console.error("Error checking authentication:", error);
          setIsAuthenticated(false);
          setUser(null);
        }
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
      setLoading(false);
    };

    checkAuthentication();
  }, []);

  const login = async (token) => {
    try {
      Cookies.set("__session", token, {
        domain: "dev.cardrealm.io", // Ensure this matches your domain
        sameSite: "Strict",
        secure: true,
      });

      await getCsrfToken(); // Ensure the CSRF token is fetched

      const response = await fetch("/api/authentication/verify-token/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "X-CSRFToken": Cookies.get("csrftoken"), // Include the CSRF token
        },
      });

      if (response.ok) {
        setIsAuthenticated(true);
        const userInfoResponse = await fetch("/api/authentication/user-info/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });

        if (userInfoResponse.ok) {
          const userData = await userInfoResponse.json();
          setUser(userData);
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  const logout = async () => {
    try {
      await getCsrfToken(); // Ensure the CSRF token is fetched

      const response = await fetch("/api/authentication/logout/", {
        method: "POST",
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"), // Include the CSRF token
        },
      });

      if (response.ok) {
        Cookies.remove("__session", { domain: "dev.cardrealm.io" });
        setIsAuthenticated(false);
        setUser(null);
      } else {
        throw new Error("Logout request failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
