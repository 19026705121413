import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/misc/Card";
import Button from "../components/forms/Button";
import BaseLayout from "../components/layouts/BaseLayout";
import { ThemeContext } from "../components/layouts/ThemeContext";

function HomePage() {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const barColor = theme === "dark" ? "bg-[#003268]" : "bg-[#FF6F61]";

  const handleGetStartedClick = () => {
    navigate("/signup");
  };

  const logoSrc = theme === "dark" ? "/static/logo_dark_full.png" : "/static/logo_light_full.png";

  return (
    <BaseLayout>
      <section className="text-center py-20">
        <Card
          title="Welcome to CardRealm!"
          titleSize="text-3xl"
          logoSrc={logoSrc}
          logoPosition="top-left"
          titlePosition="center"
        >
          <p className="m-16 text-left">
            Manage your Magic: The Gathering (MTG) collections and decks with unparalleled ease and efficiency. At CardRealm, we provide a comprehensive suite of tools designed to enhance your MTG experience, whether you're a seasoned player or a newcomer to the game.
          </p>
          <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
          <h2 className="text-2xl font-semibold text-left">Key Features:</h2>

          <ul className="list-disc list-inside m-8 text-left">
            <li>Organize and keep track of your entire MTG card collection with advanced sorting and filtering options.</li>
            <li>Create, manage, and optimize your decks with intuitive tools and performance analytics.</li>
            <li>Stay updated with real-time pricing and trends for your cards, and set alerts for price changes.</li>
            <li>Manage your trade lists and wishlist seamlessly, and track your trade history.</li>
            <li>Connect with fellow MTG enthusiasts, share your collections and decks, and participate in community discussions.</li>
            <li>Find the cards you need quickly with powerful search and filtering capabilities.</li>
            <li>Gain valuable insights into your collection and deck performance with detailed analytics and visualizations.</li>
            <li>Receive personalized notifications for price changes, trades, and other important updates.</li>
            <li>Sync your data with other popular MTG tools and platforms for a seamless experience.</li>
          </ul>

          <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />

          <p className="m-8 text-left">
            Discover the ultimate tool for managing your Magic: The Gathering collections and decks. Get started with CardRealm today and elevate your MTG gameplay to new heights.
          </p>
          <div className="align-center">
            <Button onClick={handleGetStartedClick}>
              Get Started!
            </Button>
          </div>
        </Card>
      </section>

      <section className="py-20">
        <Card
          title="Features:"
          titleSize="text-3xl"
          titlePosition="center"
        >
          <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div>
              <p className="m-8 text-left">
                <h2 className="text-xl"><strong>Comprehensive Card Database:</strong></h2>
                <div className="m-8">Access detailed information on every MTG card, including images, rulings, and related content.</div>
                <h2 className="text-xl"><strong>Smart Deck Building:</strong></h2>
                <div className="m-8">Utilize our intelligent deck-building tools to create winning combinations. Import, export, and analyze decks with ease.</div>
                <h2 className="text-xl"><strong>Trade and Wishlist Management:</strong></h2>
                <div className="m-8">Simplify your trades with a streamlined trade list and wishlist management system.</div>
                <h2 className="text-xl"><strong>Community Engagement:</strong></h2>
                <div className="m-8">Connect with other MTG enthusiasts, share your decks, and participate in community discussions.</div>
                <h2 className="text-xl"><strong>Advanced Analytics:</strong></h2>
                <div className="m-8">Gain insights into your collection and deck performance with our detailed analytics and visual reports.</div>
                <h2 className="text-xl"><strong>Customizable Notifications:</strong></h2>
                <div className="m-8">Receive notifications tailored to your preferences for price changes, trade offers, and more.</div>
                <h2 className="text-xl"><strong>Cross-Platform Integration:</strong></h2>
                <div className="m-8">Seamlessly sync your data with other popular MTG tools and platforms.</div>
                <h2 className="text-xl"><strong>Secure and Private:</strong></h2>
                <div className="m-8">Rest assured that your data is protected with our top-notch security measures, including two-factor authentication and encrypted data storage.</div>
              </p>
            </div>
            <div className="flex items-center justify-center h-full">
              <img className="w-full max-w-md mx-auto border border-green-300 rounded-lg" src="static/features.png" alt="Features" />
            </div>
          </div>
        </Card>
      </section>

      <section className="py-20">
        <Card
          title="About Us:"
          titleSize="text-3xl"
          titlePosition="center"
        >
          <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <img className="w-full max-w-md mx-auto border border-green-300 rounded-lg" src="static/about_us.png" alt="About Us" />
            </div>
            <div>
              <p className="m-8 text-left">
                <h2 className="text-xl"><strong>Our Mission</strong></h2>
                At CardRealm, our mission is to empower Magic: The Gathering (MTG) players by providing a comprehensive and intuitive platform for managing their collections, building competitive decks, and engaging with a vibrant community. We are dedicated to enhancing your MTG experience through innovative features and exceptional user support.<br /><br />
                <h2 className="text-xl"><strong>Our Vision</strong></h2>
                We envision a world where every MTG player, from casual collectors to professional competitors, has access to the tools and resources they need to fully enjoy and excel in the game. By continuously improving and expanding our platform, we aim to become the go-to resource for all things MTG.
                <br /><br />
                <h2 className="text-xl"><strong>The Mind Behind CardRealm</strong></h2>
                <h3><strong>Simon Lefebvre - Founder & CEO</strong></h3>
                Simon Lefebvre is a passionate MTG player. His passion for the game, combined with his expertise in software development, drives the innovative features and user-friendly design of CardRealm. As the sole person behind CardRealm, Simon is dedicated to ensuring the platform meets the diverse needs of the MTG community.
                <br /><br />
                <h2 className="text-xl"><strong>Our Story</strong></h2>
                CardRealm was born out of a love for Magic: The Gathering and a desire to create a platform that meets the diverse needs of the MTG community. Simon recognized the challenges faced by players in managing their collections and building decks efficiently. With a vision to address these challenges, he embarked on a journey to develop a tool that integrates advanced features with an easy-to-use interface.
                <br /><br />
                <h2 className="text-xl"><strong>Join Us</strong></h2>
                We invite you to join the CardRealm community and be a part of our growing family. Whether you're a casual player or a competitive pro, CardRealm is designed to enhance your MTG experience and help you connect with fellow enthusiasts. Together, let's take your MTG game to the next level.
                <br /><br />
                <h2 className="text-xl"><strong>Contact Us</strong></h2>
                Have questions or feedback? We'd love to hear from you! Reach out to us at <a href="mailto:simon.lefebvre@cardrealm.io">simon.lefebvre@cardrealm.io</a> or connect with us on our social media channels.
                <br /><br />
              </p>
            </div>
          </div>
        </Card>
      </section>

      <section className="py-20">
        <Card
          title="Pricing Plans:"
          titleSize="text-3xl"
          titlePosition="center"
        >
          <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
          <div className="m-8">
            <p>
              At CardRealm, we offer flexible pricing plans to suit the needs of every Magic: The Gathering player, whether you're a casual collector or a competitive pro. Each plan is designed to provide you with the tools and features you need to manage your collections, build powerful decks, and connect with the community.
            </p>
          </div>
          <div className="flex flex-col space-y-8 items-center mt-8">
            <Card
              title="Free Plan"
              titleSize="text-3xl"
              titlePosition="center"
              className="border border-green-300 rounded-lg shadow-lg"
            >
              <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                <div className="flex-1 md:mr-8">
                  <p className="text-left">
                    Perfect for beginners and casual players
                  </p>
                  <ul className="list-disc list-inside mt-4">
                    <li>Manage up to 10,000 cards in your collection</li>
                    <li>Basic deck building tools</li>
                    <li>Access to the card database</li>
                    <li>Price tracking for up to 10 cards</li>
                    <li>Community access and social features</li>
                    <li>Basic support</li>
                  </ul>
                  <div className="mt-4">
                    <Button
                      onClick={() => navigate("/signup")}
                      className="w-full md:w-auto"
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
                <div className="flex justify-center mt-8 md:mt-0">
                  <img src="/static/free_plan.png" alt="Free Plan" className="w-full max-w-xs md:max-w-sm" />
                </div>
              </div>
            </Card>

            <Card
              title="Pro Plan"
              titleSize="text-3xl"
              titlePosition="center"
              className="border border-green-300 rounded-lg shadow-lg"
            >
              <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                <div className="flex-1 md:mr-8">
                  <p className="text-left">
                    Ideal for dedicated players and collectors
                  </p>
                  <ul className="list-disc list-inside mt-4">
                    <li>Unlimited card collection management</li>
                    <li>Advanced deck building tools</li>
                    <li>Comprehensive card database access</li>
                    <li>Real-time price tracking and alerts</li>
                    <li>Trade and wish list management</li>
                    <li>Priority community access and social features</li>
                    <li>Priority support</li>
                  </ul>
                  <div className="mt-4">
                    <p>Starting at $9.99/month</p>
                    <Button
                      onClick={() => navigate("/signup")}
                      className="w-full md:w-auto"
                    >
                      Upgrade to Pro
                    </Button>
                  </div>
                </div>
                <div className="flex justify-center mt-8 md:mt-0">
                  <img src="/static/pro_plan.png" alt="Pro Plan" className="w-full max-w-xs md:max-w-sm" />
                </div>
              </div>
            </Card>

            <Card
              title="Elite Plan"
              titleSize="text-3xl"
              titlePosition="center"
              className="border border-green-300 rounded-lg shadow-lg"
            >
              <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                <div className="flex-1 md:mr-8">
                  <p className="text-left">
                    Designed for competitive players and professionals
                  </p>
                  <ul className="list-disc list-inside mt-4">
                    <li>All features in the Pro Plan</li>
                    <li>Advanced analytics and insights</li>
                    <li>Customizable notifications</li>
                    <li>Access to exclusive community events</li>
                    <li>Early access to new features</li>
                    <li>Dedicated customer support</li>
                  </ul>
                  <div className="mt-4">
                    <p>Starting at $19.99/month</p>
                    <Button
                      onClick={() => navigate("/signup")}
                      className="w-full md:w-auto"
                    >
                      Upgrade to Elite
                    </Button>
                  </div>
                </div>
                <div className="flex justify-center mt-8 md:mt-0">
                  <img src="/static/elite_plan.png" alt="Elite Plan" className="w-full max-w-xs md:max-w-sm" />
                </div>
              </div>
            </Card>

            <Card
              title="Enterprise Plan"
              titleSize="text-3xl"
              titlePosition="center"
              className="border border-green-300 rounded-lg shadow-lg"
            >
              <div className="m-8 flex flex-col md:flex-row justify-between items-center">
                <div className="flex-1 md:mr-8">
                  <p className="text-left">
                    For gaming stores, organizations, and large-scale collectors
                  </p>
                  <ul className="list-disc list-inside mt-4">
                    <li>All features in the Elite Plan</li>
                    <li>Multiple user accounts</li>
                    <li>Custom integrations and APIs</li>
                    <li>Dedicated account manager</li>
                    <li>Personalized training and support</li>
                    <li>Custom pricing based on requirements</li>
                  </ul>
                  <div className="mt-4">
                    <p>Contact for pricing information</p>
                    <Button
                      onClick={() => navigate("/signup")}
                      className="w-full md:w-auto"
                    >
                      Upgrade to Enterprise
                    </Button>
                  </div>
                </div>
                <div className="flex justify-center mt-8 md:mt-0">
                  <img src="/static/enterprise_plan.png" alt="Enterprise Plan" className="w-full max-w-xs md:max-w-sm" />
                </div>
              </div>
            </Card>
          </div>
        </Card>
      </section>

      <section className="mt-8">
        <Card
          title="Pricing Summary"
          titleSize="text-3xl"
          titlePosition="center"
          className="border border-green-300 rounded-lg shadow-lg"
        >
          <div className="overflow-x-auto">
            <table className="w-full table-fixed">
              <thead>
                <tr>
                  <th className="w-1/12 border border-green-300 px-4 py-2">Plan</th>
                  <th className="w-1/6 border border-green-300 px-4 py-2">Price</th>
                  <th className="w-4/6 border border-green-300 px-4 py-2">Features</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-green-300 px-4 py-2">Basic</td>
                  <td className="border border-green-300 px-4 py-2">Free</td>
                  <td className="border border-green-300 px-4 py-2">
                    <p>
                      <strong>Perfect for beginners and casual players</strong>
                    </p>
                    <ul className="list-disc list-inside">
                      <li>Manage up to 10,000 cards in your collection</li>
                      <li>Basic deck building tools</li>
                      <li>Access to the card database</li>
                      <li>Price tracking for up to 10 cards</li>
                      <li>Community access and social features</li>
                      <li>Basic support</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="border border-green-300 px-4 py-2">Pro</td>
                  <td className="border border-green-300 px-4 py-2">$9.99/month</td>
                  <td className="border border-green-300 px-4 py-2">
                    <p className="text-left">
                      Ideal for dedicated players and collectors
                    </p>
                    <ul className="list-disc list-inside mt-4">
                      <li>Unlimited card collection management</li>
                      <li>Advanced deck building tools</li>
                      <li>Comprehensive card database access</li>
                      <li>Real-time price tracking and alerts</li>
                      <li>Trade and wish list management</li>
                      <li>Priority community access and social features</li>
                      <li>Priority support</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="border border-green-300 px-4 py-2">Elite</td>
                  <td className="border border-green-300 px-4 py-2">$19.99/month</td>
                  <td className="border border-green-300 px-4 py-2">
                    <p className="text-left">
                      Ideal for dedicated players and collectors
                    </p>
                    <ul className="list-disc list-inside mt-4">
                      <li>Unlimited card collection management</li>
                      <li>Advanced deck building tools</li>
                      <li>Comprehensive card database access</li>
                      <li>Real-time price tracking and alerts</li>
                      <li>Trade and wish list management</li>
                      <li>Priority community access and social features</li>
                      <li>Priority support</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="border border-green-300 px-4 py-2">Enterprise</td>
                  <td className="border border-green-300 px-4 py-2">Custom</td>
                  <td className="border border-green-300 px-4 py-2">
                    <p className="text-left">
                      Designed for competitive players and professionals
                    </p>
                    <ul className="list-disc list-inside mt-4">
                      <li>All features in the Pro Plan</li>
                      <li>Advanced analytics and insights</li>
                      <li>Customizable notifications</li>
                      <li>Access to exclusive community events</li>
                      <li>Early access to new features</li>
                      <li>Dedicated customer support</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      </section>

    </BaseLayout>
  );
}

export default HomePage;
