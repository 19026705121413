// CardDatabaseByCard.js

import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import SortableTable from "../misc/SortableTable";
import Card from "../misc/Card";
import Button from "../forms/Button";
import { renderMana } from "../../functions/renderMana";
import Filter from "../misc/Filter";
import useAxiosInstance from "../../services/axiosInstance";
import qs from "qs"; // Ensure qs is installed: npm install qs
import { ThemeContext } from "../layouts/ThemeContext";

const CardDatabaseByCard = () => {
  const axiosInstance = useAxiosInstance();
  const { theme } = useContext(ThemeContext);

  // State Management
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState({
    name: "",
    set_code: [],
    cmc: "",
    colors: [],
    color_mode: "contains",
    card_types__or: [],
    card_types__and: [],
    rarity__or: [],
    rarity__and: [],
    legalities__or: [],
    legalities__and: [],
    symbols__or: [],
    symbols__and: [],
    startDate: "",
    endDate: "",
    power: "",
    toughness: "",
    keywords__or: [],
    keywords__and: [],
    super_types__or: [],
    planeswalker_types__or: [],
    creature_types__or: [],
    land_types__or: [],
    oracle_text: "",
  });

  // Utility Functions
  const formatManaCost = (manaCost, mode) => {
    if (!manaCost) return "";
    return mode === "exact" ? manaCost : manaCost.replace(/[{}]/g, "").trim();
  };

  const cleanConditionalFields = (params, fields) => {
    fields.forEach((field) => {
      const value = params[field]?.trim();
      if (value) {
        if (value.startsWith("<")) {
          params[`${field}__lt`] = value.slice(1).trim();
        } else if (value.startsWith(">")) {
          params[`${field}__gt`] = value.slice(1).trim();
        } else if (value.startsWith("=")) {
          params[field] = value.slice(1).trim();
        }
      }
    });
  };

  // Build Query String
  const buildQuery = useCallback(() => {
    const params = { ...filter, page };

    // Format mana_cost based on color_mode
    params.mana_cost = formatManaCost(params.mana_cost, params.color_mode);
    cleanConditionalFields(params, ["cmc", "power", "toughness"]);

    // Remove empty or invalid fields
    Object.keys(params).forEach((key) => {
      if (
        !params[key] ||
        (Array.isArray(params[key]) && params[key].length === 0)
      ) {
        delete params[key];
      }
    });

    // Serialize query parameters
    return qs.stringify(params, { arrayFormat: "repeat" });
  }, [filter, page]);

  // Fetch Cards from API
  const fetchCards = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const queryString = buildQuery();
      const response = await axiosInstance.get(`/api/cards/?${queryString}`);
      setCards((prevCards) =>
        page === 1 ? response.data.results : [...prevCards, ...response.data.results]
      );
      setHasMore(!!response.data.next);
    } catch (err) {
      setError("Error fetching cards");
      console.error(err);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  }, [axiosInstance, buildQuery, page]);

  // Initial Fetch and Fetch on Filter/Page Change
  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  // Reset Page and Cards when Filter Changes
  useEffect(() => {
    setPage(1);
    setCards([]);
  }, [filter]);

  // Load More Cards
  const loadMoreCards = () => {
    if (hasMore) {
      setLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Define Table Columns
  const columns = useMemo(
    () => [
      {
        key: "image_uris",
        label: "Image",
        render: (value, card) => renderImages(card),
        width: 150,
      },
      {
        key: "name",
        label: "Card Name",
        render: (value, card) => (
          <Link to={`/dashboard/cards/${card.card_id}`} className="text-blue-500 hover:underline">
            {value}
          </Link>
        ),
        width: 200,
      },
      {
        key: "usd_price",
        label: "USD Price",
        render: (value) => `$${value || "N/A"}`,
        width: 100,
      },
      { key: "set_name", label: "Set Name", width: 150 },
      { key: "card_types", label: "Type", width: 150 },
      { key: "rarity", label: "Rarity", width: 100 },
      { key: "mana_cost", label: "Mana Cost", render: (value) => renderMana(value, theme), width: 200 },
      { key: "cmc", label: "CMC", width: 100 },
      { key: "power", label: "Power", width: 80 },
      { key: "toughness", label: "Toughness", width: 80 },
      {
        key: "keywords",
        label: "Keywords",
        render: (value) => (value ? value.join(", ") : ""),
        width: 150,
      },
    ],
    [theme]
  );

  // Render Card Images
  const renderImages = (card) => {
    const renderImage = (src, index) => (
      <img
        key={index}
        src={src}
        alt="Card"
        className="card-image transition-transform transform hover:scale-105"
        style={{ maxWidth: "150px", maxHeight: "210px", objectFit: "contain" }}
      />
    );

    if (card.card_faces?.length > 0) {
      return (
        <div className="flex space-x-2">
          {card.card_faces.map((face, index) =>
            renderImage(face.image_uris?.normal || face.image_uris?.large || face.image_uris?.small, index)
          )}
        </div>
      );
    }

    if (card.image_uris) {
      return renderImage(card.image_uris.normal || card.image_uris.large || card.image_uris.small, 0);
    }

    return null;
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-6 text-green-600 dark:text-green-400">Card Database</h1>

      {/* Filter Section */}
      <Card title="Filter Options" collapsible>
        <Filter filter={filter} setFilter={setFilter} />
      </Card>

      {/* Error Message */}
      {error && (
        <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
          {error}
        </div>
      )}

      {/* Loading Indicator */}
      {loading && page === 1 ? (
        <p>Loading cards...</p>
      ) : (
        <>
          {/* Sortable Table */}
          <SortableTable
            data={cards}
            columns={columns}
            title="Card Database"
            className="w-full sm:w-3/4 max-w-4xl mx-auto mt-6"
            enableColumnSelection
          />

          {/* Load More Button */}
          {hasMore && (
            <div className="flex justify-center mt-4">
              <Button onClick={loadMoreCards} disabled={loadingMore}>
                {loadingMore ? "Loading..." : "Load More"}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CardDatabaseByCard;
