export const manaSymbolMap = {
  "{W}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/W.svg",
  "{U}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/U.svg",
  "{B}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/B.svg",
  "{R}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/R.svg",
  "{G}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/G.svg",
  "{C}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/C.svg",
  "{X}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/X.svg",
  "{L}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/L.svg",
  "{0}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/0.svg",
  "{1}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/1.svg",
  "{2}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2.svg",
  "{3}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/3.svg",
  "{4}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/4.svg",
  "{5}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/5.svg",
  "{6}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/6.svg",
  "{7}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/7.svg",
  "{8}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/8.svg",
  "{9}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/9.svg",
  "{10}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/10.svg",
  "{11}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/11.svg",
  "{12}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/12.svg",
  "{13}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/13.svg",
  "{14}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/14.svg",
  "{15}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/15.svg",
  "{16}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/16.svg",
  "{17}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/17.svg",
  "{18}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/18.svg",
  "{19}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/19.svg",
  "{20}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/20.svg",
  "{100}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/100.svg",
  "{1000000}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/1000000.svg",
  "{WU}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WU.svg",
  "{WB}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WB.svg",
  "{BR}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BR.svg",
  "{BG}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BG.svg",
  "{UB}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UB.svg",
  "{UR}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UR.svg",
  "{RG}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RG.svg",
  "{RW}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RW.svg",
  "{GW}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GW.svg",
  "{GU}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GU.svg",
  "{2W}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2W.svg",
  "{2U}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2U.svg",
  "{2B}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2B.svg",
  "{2R}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2R.svg",
  "{2G}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2G.svg",
  "{W/U}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WU.svg",
  "{W/B}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WB.svg",
  "{B/R}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BR.svg",
  "{B/G}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BG.svg",
  "{U/B}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UB.svg",
  "{U/R}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UR.svg",
  "{R/G}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RG.svg",
  "{R/W}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RW.svg",
  "{G/W}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GW.svg",
  "{G/U}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GU.svg",
  "{2/W}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2W.svg",
  "{2/U}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2U.svg",
  "{2/B}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2B.svg",
  "{2/R}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2R.svg",
  "{2/G}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/2G.svg",
  "{BGP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BGP.svg",
  "{RWP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RWP.svg",
  "{UBP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UBP.svg",
  "{GWP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GWP.svg",
  "{GUP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GUP.svg",
  "{RGP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RGP.svg",
  "{URP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/URP.svg",
  "{BRP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BRP.svg",
  "{WUP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WUP.svg",
  "{WBP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WBP.svg",
  "{P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/P.svg",
  "{B/G/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BGP.svg",
  "{R/W/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RWP.svg",
  "{U/B/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UBP.svg",
  "{G/W/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GWP.svg",
  "{G/U/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GUP.svg",
  "{R/G/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RGP.svg",
  "{U/R/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/URP.svg",
  "{B/R/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BRP.svg",
  "{W/U/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WUP.svg",
  "{W/B/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WBP.svg",
  "{P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/P.svg",
  "{S}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/S.svg",
  "{Q}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/Q.svg",
  "{E}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/E.svg",
  "{PW}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/PW.svg",
  "{CHAOS}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CHAOS.svg",
  "{A}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/A.svg",
  "{TK}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/TK.svg",
  "{½}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/half.svg",
  "{∞}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/infinity.svg",
  "{WP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WP.svg",
  "{UP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UP.svg",
  "{BP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BP.svg",
  "{RP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RP.svg",
  "{GP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GP.svg",
  "{CP}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CP.svg",
  "{CW}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CW.svg",
  "{CU}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CU.svg",
  "{CB}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CB.svg",
  "{CR}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CR.svg",
  "{CG}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CG.svg",
  "{W/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/WP.svg",
  "{U/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/UP.svg",
  "{B/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/BP.svg",
  "{R/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/RP.svg",
  "{G/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/GP.svg",
  "{C/P}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CP.svg",
  "{C/W}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CW.svg",
  "{C/U}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CU.svg",
  "{C/B}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CB.svg",
  "{C/R}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CR.svg",
  "{C/G}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/CG.svg",
  "{HW}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/HW.svg",
  "{HR}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/HR.svg",
  "{T}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/T.svg",
  "{D}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/D.svg",
  "{H}": "https://c2.scryfall.com/file/scryfall-symbols/card-symbols/H.svg",

};


export const renderMana = (manaCost, theme = "light") => {
  // Early return if manaCost is not a valid string
  if (typeof manaCost !== 'string' || manaCost.trim() === '') return null;

  // List of symbols to invert in dark mode
  const invertSymbols = new Set(['{PW}', '{CHAOS}', '{TK}', '{L}', '{D}', '{P}']); // Use Set for faster lookup

  // console.log("Received theme:", theme); // Add a log here

  // Split the manaCost string by symbols using regex and map over the parts
  return manaCost.split(/(\{[A-Za-z0-9/]+\})/g).map((symbol, index) => {
    const symbolImage = manaSymbolMap[symbol];
    const shouldInvert = invertSymbols.has(symbol) && theme === "dark";

    // Debugging log
    // console.log("Symbol:", symbol, "Should Invert:", shouldInvert, "Theme:", theme);

    // Check if it's a recognized mana symbol
    if (symbolImage) {
      return (
        <img
          key={index}
          src={symbolImage}
          alt={symbol}
          className={`inline w-6 h-6 ${shouldInvert ? 'invert' : ''}`} // Conditionally apply 'invert'
        />
      );
    }

    // Render plain text for unrecognized or non-mana symbols
    return <span key={index}>{symbol}</span>;
  });
};

