import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxiosInstance from "../services/axiosInstance";
import BaseLayout from "../components/layouts/BaseLayout";
import Card from "../components/misc/Card";

const VerifyEmailPage = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const [message, setMessage] = useState("Verifying your email...");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axiosInstance.get(`/api/authentication/verify-email/${uid}/${token}/`);
        setMessage(response.data.message);
        setTimeout(() => navigate("/login"), 3000);
      } catch (error) {
        setMessage(error.response?.data?.error || "An error occurred during email verification.");
      }
    };
    verifyEmail();
  }, [uid, token, axiosInstance, navigate]);

  return (
    <BaseLayout>
      <div className="flex justify-center items-center h-screen">
        <Card title="Email Verification" titleSize="text-3xl">
          <p className="text-center mt-4">{message}</p>
        </Card>
      </div>
    </BaseLayout>
  );
};

export default VerifyEmailPage;
