import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";

const Background = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-0 transition-colors duration-500 ${
        theme === "dark" ? "bg-gray-900" : "bg-white"
      }`}
    />
  );
};

export default Background;
