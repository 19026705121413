import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Navbar from "../layouts/Navbar";
import Sidebar from "../layouts/Sidebar";
import Card from "../misc/Card";
import { ThemeContext } from "./ThemeContext";
import Footer from "../layouts/Footer";
import { renderMana } from "../../functions/renderMana";



const contentVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const DashboardLayout = () => {
  const { theme } = useContext(ThemeContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className={`min-h-screen flex flex-col overflow-hidden ${theme === "dark" ? "bg-[#003268] text-gray-100" : "bg-[#FF6F61] text-gray-900"
        } font-roboto relative`}
    >
      <Navbar onMenuClick={toggleSidebar} />
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div
          className={`fixed inset-y-0 md:static md:block ${isSidebarOpen ? "block" : "hidden"
            } md:w-64 w-full bg-gray-800 z-30`}
        >
          <Sidebar />
        </div>

        {/* Overlay for small screens when sidebar is open */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-20"
            onClick={toggleSidebar}
          ></div>
        )}

        {/* Main Content */}
        <motion.main
          className="flex-1 flex flex-col p-4 md:p-6 overflow-auto"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={contentVariants}
          transition={{ duration: 0.5 }}
        >
          <div className="flex-grow w-full max-w-full md:max-w-5xl mx-auto">
            <Card>
              <Outlet />
            </Card>
          </div>
        </motion.main>
      </div>
      <AnimatePresence>
        <motion.div
          key="footer"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
        >
          <Footer />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default DashboardLayout;
