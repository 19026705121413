import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "./components/layouts/ThemeContext";
import { AuthProvider } from "./services/AuthContext";
import "./index.css"; // Import your global styles

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ThemeProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ThemeProvider>
);
