import React, { useContext, useState, useEffect } from "react";
import {
  FaHome,
  FaFolder,
  FaToolbox,
  FaChartPie,
  FaCog,
  FaDatabase,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { ThemeContext } from "./ThemeContext";
import { motion, AnimatePresence } from "framer-motion";

const sidebarVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};

const Sidebar = () => {
  const { theme } = useContext(ThemeContext);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize(); // Set initial state based on screen size
  }, []);

  const navItems = [
    { to: "/dashboard", icon: FaHome, label: "Home" },
    { to: "/dashboard/collection", icon: FaFolder, label: "Collections" },
    { to: "/dashboard/deckbuilder", icon: FaToolbox, label: "Deck Builder" },
    { to: "/dashboard/stats", icon: FaChartPie, label: "Stats" },
    { to: "/dashboard/settings", icon: FaCog, label: "Settings" },
    { to: "/dashboard/carddatabase", icon: FaDatabase, label: "Card Database" },
  ];

  return (
    <AnimatePresence>
      {isDesktop && (
        <motion.div
          className={`fixed md:relative top-0 left-0 h-full shadow-lg w-64 ${
            theme === "dark"
              ? "bg-gray-800 text-gray-200"
              : "bg-white text-gray-900"
          }`}
          initial="closed"
          animate="open"
          exit="closed"
          variants={sidebarVariants}
          transition={{ duration: 0.3 }}
        >
          <div className="flex flex-col items-center md:items-start p-4 space-y-4">
            {navItems.map((item) => (
              <NavLink
                key={item.to}
                to={item.to}
                className={`w-full h-16 flex items-center px-4 ${
                  theme === "dark" ? "hover:bg-gray-700" : "hover:bg-gray-200"
                }`}
                activeClassName={
                  theme === "dark" ? "bg-gray-700" : "bg-gray-300 text-gray-900"
                }
              >
                <item.icon className="w-6 h-6 md:w-8 md:h-8" />
                <span className="ml-2 hidden md:inline">{item.label}</span>
              </NavLink>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
