import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const useAxiosInstance = () => {
  const [axiosInstance, setAxiosInstance] = useState(() => {
    const instance = axios.create({
      baseURL: "https://dev.cardrealm.io",
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
    });

    instance.interceptors.request.use(
      (config) => {
        const csrfToken = Cookies.get("csrftoken");
        if (csrfToken) {
          config.headers["X-CSRFToken"] = csrfToken;
        }

        const sessionToken = Cookies.get("__session");
        if (sessionToken) {
          config.headers["Authorization"] = `Bearer ${sessionToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    return instance;
  });

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        console.log("Fetching CSRF token...");
        const response = await axios.get("/api/authentication/csrf-token/");
        const csrfToken = response.data.csrfToken;
        Cookies.set("csrftoken", csrfToken);
        console.log("CSRF token fetched and set:", csrfToken);
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
      }
    };

    fetchCsrfToken();
  }, []);

  return axiosInstance;
};

export default useAxiosInstance;
