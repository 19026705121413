import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaUser,
  FaMoon,
  FaSun,
  FaBars,
  FaTimes,
  FaHome,
  FaFolder,
  FaToolbox,
  FaChartPie,
  FaCog,
  FaDatabase,
  FaThLarge,
} from "react-icons/fa";
import Cookies from "js-cookie";
import { AuthContext } from "../../services/AuthContext";
import { ThemeContext } from "./ThemeContext";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../forms/Button";

const clearCookies = () => {
  const cookies = Cookies.get();
  for (let cookie in cookies) {
    if (cookie !== "theme") {
      Cookies.remove(cookie, { path: "/" });
    }
  }
};

const Navbar = () => {
  const { isAuthenticated, logout, user } = useContext(AuthContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const csrfToken = Cookies.get("csrftoken");
      const response = await fetch("/api/authentication/logout/", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
        },
      });

      if (!response.ok) {
        throw new Error("Logout request failed");
      }

      await logout();
      clearCookies();
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const authLink = isAuthenticated
    ? { label: "Logout", href: "#", onClick: handleLogout }
    : { label: "Login", href: "/login", onClick: null };

  const userButton = isAuthenticated
    ? {
      label: `${user?.username || user?.first_name || "User"}'s Account`,
      icon: user?.profile?.avatar ? (
        <img
          src={user.profile.avatar}
          alt="Avatar"
          className="w-6 h-6 rounded-full"
        />
      ) : (
        <FaUser />
      ),
    }
    : { label: "Login", icon: <FaUser /> };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const userDropdownItems = [
    {
      label: "Dashboard",
      href: "/dashboard",
      icon: FaThLarge,
      onClick: () => setMobileMenuOpen(false),
    },
    {
      label: "My Account",
      href: "/profile",
      onClick: () => setMobileMenuOpen(false),
    },
    {
      label: authLink.label,
      href: authLink.href,
      onClick: authLink.onClick,
    },
  ];

  const navItems = [
    { to: "/dashboard", icon: FaHome, label: "Home" },
    { to: "/dashboard/collections", icon: FaFolder, label: "Collections" },
    { to: "/dashboard/deckbuilder", icon: FaToolbox, label: "Deck Builder" },
    { to: "/dashboard/stats", icon: FaChartPie, label: "Stats" },
    { to: "/dashboard/settings", icon: FaCog, label: "Settings" },
    { to: "/dashboard/carddatabase", icon: FaDatabase, label: "Card Database" },
  ];

  const staticNavItems = [
    { to: "/", icon: FaHome, label: "Home" },
    { to: "/about", icon: FaFolder, label: "About" },
    { to: "/features", icon: FaToolbox, label: "Features" },
    { to: "/pricing", icon: FaChartPie, label: "Pricing" },
    { to: "/blog", icon: FaCog, label: "Blog" },
    { to: "/contact", icon: FaDatabase, label: "Contact" },
  ];

  return (
    <nav
      className={`w-full py-4 md:py-6 shadow-md z-20 transition-colors duration-300 ${theme === "dark" ? "bg-gray-800 text-gray-100" : "bg-gray-100 text-gray-900"
        }`}
    >
      <div className="container mx-auto flex justify-between items-center px-4 md:px-6">
        <div className="flex items-center space-x-2 md:space-x-4">
          <img
            src="/static/logo.png"
            alt="Logo"
            className="h-8 w-8 md:h-10 md:w-10 rounded-full"
          />
          <h1
            className={`text-xl md:text-3xl font-bold transition-colors duration-300 ${theme === "dark" ? "text-white" : "text-black"
              }`}
          >
            Card<span className="text-green-600">Realm</span>
          </h1>
        </div>
        <div className="hidden md:flex space-x-4 md:space-x-6">
          {staticNavItems.map((item) => (
            <NavLink
              key={item.to}
              to={item.to}
              className={`transition-colors duration-300 ${theme === "dark"
                ? "text-gray-300 hover:text-gray-100"
                : "text-gray-600 hover:text-gray-900"
                }`}
            >
              <item.icon className="inline-block mr-2" />
              {item.label}
            </NavLink>
          ))}
        </div>
        <div className="flex items-center space-x-2 md:space-x-4 relative">
          <button
            onClick={toggleTheme}
            className="flex items-center px-2 py-2 md:px-4 md:py-2 rounded font-semibold transition duration-300"
          >
            {theme === "dark" ? (
              <>
                <FaSun className="mr-1 md:mr-2" />
                <span className="hidden md:inline">Light Mode</span>
              </>
            ) : (
              <>
                <FaMoon className="mr-1 md:mr-2" />
                <span className="hidden md:inline">Dark Mode</span>
              </>
            )}
          </button>
          <Button icon={userButton.icon} dropdownItems={userDropdownItems}>
            <span className="hidden md:inline">
              {userButton.label}
            </span>
          </Button>
          <button
            onClick={handleMobileMenuToggle}
            className="md:hidden flex items-center px-2 py-2 rounded font-semibold transition duration-300"
            aria-label={mobileMenuOpen ? "Close Menu" : "Open Menu"}
          >
            {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.nav
            className={`md:hidden w-full px-4 py-2 z-10 transition-colors duration-300 ${theme === "dark" ? "bg-gray-800" : "bg-white"
              }`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            {staticNavItems.map((item) => (
              <NavLink
                key={item.to}
                to={item.to}
                className={`block transition-colors duration-300 ${theme === "dark"
                  ? "text-gray-300 hover:text-gray-100"
                  : "text-gray-600 hover:text-gray-900"
                  } py-2`}
                onClick={() => setMobileMenuOpen(false)}
              >
                <item.icon className="inline-block mr-2" />
                {item.label}
              </NavLink>
            ))}
            {isAuthenticated &&
              navItems.map((item) => (
                <NavLink
                  key={item.to}
                  to={item.to}
                  className={`block transition-colors duration-300 ${theme === "dark"
                    ? "text-gray-300 hover:text-gray-100"
                    : "text-gray-600 hover:text-gray-900"
                    } py-2`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <item.icon className="inline-block mr-2" />
                  {item.label}
                </NavLink>
              ))}
          </motion.nav>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
