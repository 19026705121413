import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Card from "../components/misc/Card";
import SortableTable from "../components/misc/SortableTable";
import { ThemeContext } from "../components/layouts/ThemeContext";
import PriceFilter from "../components/misc/PriceFilter";
import { renderMana } from "../functions/renderMana";
import Button from "../components/forms/Button";

const CardDetails = () => {
  const { cardId } = useParams();
  const [card, setCard] = useState(null);
  const [prints, setPrints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { theme } = useContext(ThemeContext);
  const [exchangeRates, setExchangeRates] = useState({ CAD: 1.25, EUR: 0.85 }); // Default exchange rates
  const [showMore, setShowMore] = useState(false); // State to manage "Show More" toggle
  const [priceFilter, setPriceFilter] = useState({
    set_code: "all",
    source: "",
  });
  const [sets, setSets] = useState([]);
  const [filteredSets, setFilteredSets] = useState([]);

  useEffect(() => {
    const fetchSets = async () => {
      try {
        const response = await axios.get("https://api.scryfall.com/sets");
        setSets(response.data.data);
      } catch (error) {
        console.error("Error fetching sets:", error);
      }
    };

    fetchSets();
  }, []);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.scryfall.com/cards/${cardId}`
        );
        setCard(response.data);

        // Fetch all available prints of the card
        if (response.data.prints_search_uri) {
          const printsResponse = await axios.get(
            response.data.prints_search_uri
          );
          setPrints(printsResponse.data.data);

          // Filter sets to only include those that have the card
          const availableSetCodes = printsResponse.data.data.map(
            (print) => print.set
          );
          setFilteredSets(
            sets.filter((set) => availableSetCodes.includes(set.code))
          );
        }
      } catch (error) {
        console.error("Error fetching card details:", error);
        setError("Error fetching card details");
      } finally {
        setLoading(false);
      }
    };

    fetchCardDetails();
  }, [cardId, sets]);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get(
          `https://v6.exchangerate-api.com/v6/42410755538e36d03da2c2eb/latest/USD`
        );
        const rates = response.data.conversion_rates;
        setExchangeRates({ CAD: rates.CAD, EUR: rates.EUR });
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRates();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }

  if (!card) {
    return <div>No card details available</div>;
  }

  const renderLegalityTable = () => (
    <div className="overflow-x-auto">
      <SortableTable
        columns={[
          { key: "format", label: "Format" },
          { key: "legality", label: "Legality" },
        ]}
        data={Object.entries(card.legalities).map(([format, legality]) => ({
          format,
          legality,
        }))}
      />
    </div>
  );

  const calculateAveragePrices = (prints) => {
    const totals = {
      usd: 0,
      usd_foil: 0,
      usd_etched: 0,
      eur: 0,
      cad: 0,
      tix: 0,
    };
    const counts = {
      usd: 0,
      usd_foil: 0,
      usd_etched: 0,
      eur: 0,
      cad: 0,
      tix: 0,
    };

    prints.forEach((print) => {
      if (print.prices) {
        if (print.prices.usd) {
          totals.usd += parseFloat(print.prices.usd);
          counts.usd += 1;
          totals.cad += parseFloat(print.prices.usd) * exchangeRates.CAD;
          counts.cad += 1;
        }
        if (print.prices.usd_foil) {
          totals.usd_foil += parseFloat(print.prices.usd_foil);
          counts.usd_foil += 1;
        }
        if (print.prices.usd_etched) {
          totals.usd_etched += parseFloat(print.prices.usd_etched);
          counts.usd_etched += 1;
        }
        if (print.prices.eur) {
          totals.eur += parseFloat(print.prices.eur);
          counts.eur += 1;
        }
        if (print.prices.tix) {
          totals.tix += parseFloat(print.prices.tix);
          counts.tix += 1;
        }
      }
    });

    return {
      usd: counts.usd ? (totals.usd / counts.usd).toFixed(2) : "N/A",
      usd_foil: counts.usd_foil
        ? (totals.usd_foil / counts.usd_foil).toFixed(2)
        : "N/A",
      usd_etched: counts.usd_etched
        ? (totals.usd_etched / counts.usd_etched).toFixed(2)
        : "N/A",
      eur: counts.eur ? (totals.eur / counts.eur).toFixed(2) : "N/A",
      cad: counts.cad ? (totals.cad / counts.cad).toFixed(2) : "N/A",
      tix: counts.tix ? (totals.tix / counts.tix).toFixed(2) : "N/A",
    };
  };

  const filteredPrints = prints.filter((print) => {
    return (
      (priceFilter.set_code === "all" ||
        print.set.toLowerCase().includes(priceFilter.set_code.toLowerCase())) &&
      (!priceFilter.source ||
        (priceFilter.source === "Foil" && print.prices?.usd_foil) ||
        (priceFilter.source === "Etched" && print.prices?.usd_etched) ||
        (priceFilter.source === "Normal" && print.prices?.usd))
    );
  });

  const averagePrices = calculateAveragePrices(filteredPrints);

  const renderPricesTable = () => {
    const priceSources = {
      usd_foil: "Foil",
      usd_etched: "Etched",
      eur_foil: "Foil",
      tix: "MTGO",
      usd: "Normal",
    };

    const pricesToShow =
      showMore || filteredPrints.length <= 10
        ? filteredPrints
        : filteredPrints.slice(0, 10);

    const columns = [
      {
        key: "set_name",
        label: "Print",
        render: (value) => value,
      },
      {
        key: "print",
        label: "Print Type",
        render: (value, print) => priceSources[print.print],
      },
      {
        key: "source",
        label: "Source",
        render: (value, item) => {
          if (item.highestUsd && item.print.includes("usd")) {
            return "Star City Games";
          } else if (value.includes("usd")) {
            return "TCG Player";
          } else if (value.includes("eur")) {
            return "CardMarket";
          } else if (value.includes("tix")) {
            return "MTGO";
          } else {
            return "Unknown";
          }
        },
      },
      {
        key: "price",
        label: "Price",
        render: (value, print) =>
          value !== undefined
            ? print.source === "eur"
              ? `€${value}`
              : print.source === "tix"
              ? `${value} TIX`
              : `$${value}`
            : "-",
      },
      {
        key: "cad",
        label: "Price (CAD)",
        render: (value) => (value !== undefined ? `$${value.toFixed(2)}` : "-"),
      },
    ];

    const data = pricesToShow.flatMap((print) =>
      Object.entries(print.prices || {})
        .filter(([currency, price]) => {
          if (
            !price ||
            (priceFilter.source === "Foil" && !currency.includes("foil")) ||
            (priceFilter.source === "Etched" && !currency.includes("etched")) ||
            (priceFilter.source === "Normal" &&
              (currency.includes("foil") || currency.includes("etched")))
          ) {
            return false;
          }
          return true;
        })
        .map(([currency, price]) => ({
          set_name: print.set_name,
          print: currency,
          source: currency,
          price: price,
          highestUsd: print.highestUsd,
          cad: currency.includes("usd")
            ? price * exchangeRates.CAD
            : currency.includes("eur")
            ? (price * exchangeRates.CAD) / exchangeRates.EUR
            : undefined,
        }))
    );

    // Mark the highest USD price
    let highestUsdPrice = -1;
    let highestUsdIndex = -1;

    data.forEach((entry, index) => {
      if (entry.print === "usd" && entry.price > highestUsdPrice) {
        highestUsdPrice = entry.price;
        highestUsdIndex = index;
      }
    });

    if (highestUsdIndex !== -1) {
      data[highestUsdIndex].highestUsd = true;
    }

    return (
      <>
        <div className="overflow-x-auto">
          <SortableTable columns={columns} data={data} />
        </div>
        {filteredPrints.length > 10 && (
          <div className="flex justify-center mt-4">
            <Button onClick={() => setShowMore(!showMore)}>
              {showMore ? "Show Less" : "Show More"}
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderCardImages = () => {
    if (card.card_faces) {
      return (
        <div className="flex justify-center mb-6">
          {card.card_faces.map((face, index) => (
            <img
              key={index}
              src={face.image_uris?.normal}
              alt={`Face ${index + 1}`}
              className="w-full h-auto object-contain max-w-xs"
            />
          ))}
        </div>
      );
    } else if (card.image_uris) {
      return (
        <div className="flex justify-center mb-6">
          <img
            src={card.image_uris.normal}
            alt={card.name}
            className="w-full h-auto object-contain max-w-xs"
          />
        </div>
      );
    }
    return null;
  };

  const renderCardDetails = () => {
    const columns = [
      { key: "label", label: "Detail" },
      { key: "value", label: "Value" },
    ];

    const data = [
      { label: "Type", value: card.type_line },
      {
        label: "Oracle Text",
        value: card.oracle_text ? renderMana(card.oracle_text) : "-",
      },
      { label: "Flavor Text", value: card.flavor_text || "-" },
      {
        label: "Rarity",
        value: card.rarity.charAt(0).toUpperCase() + card.rarity.slice(1),
      },
      { label: "Set", value: card.set_name },
      { label: "Artist", value: card.artist },
      { label: "Power", value: card.power || "-" },
      { label: "Toughness", value: card.toughness || "-" },
      { label: "Loyalty", value: card.loyalty || "-" },
      {
        label: "Mana Cost",
        value: card.mana_cost ? renderMana(card.mana_cost) : "-",
      },
      {
        label: "Converted Mana Cost",
        value: card.cmc !== undefined ? card.cmc : "-",
      },
      {
        label: "Keywords",
        value: card.keywords.length > 0 ? card.keywords.join(", ") : "-",
      },
    ];

    return (
      <div className="overflow-x-auto mt-4">
        <SortableTable columns={columns} data={data} />
      </div>
    );
  };

  return (
    <div
      className={`p-4 ${
        theme === "dark" ? "bg-gray-900 text-white" : "bg-white text-black"
      }`}
    >
      <h1 className="text-2xl font-bold mb-4 text-center">{card.name}</h1>
      {renderCardImages()}
      {renderCardDetails()}
      <div className="mt-4">
        <h2 className="text-xl font-bold">Legality</h2>
        {renderLegalityTable()}
      </div>
      <div className="mt-4">
        <h2 className="text-xl font-bold">Prices</h2>
        <PriceFilter
          filter={priceFilter}
          setFilter={setPriceFilter}
          sets={filteredSets}
        />
        <div className="mb-4">
          <div className="flex flex-wrap justify-between">
            {priceFilter.source !== "Foil" &&
              priceFilter.source !== "Etched" && (
                <>
                  <div className="w-1/2 md:w-auto mb-2 md:mb-0">
                    <strong>Average USD:</strong> ${averagePrices.usd}
                  </div>
                  <div className="w-1/2 md:w-auto mb-2 md:mb-0">
                    <strong>Average EUR:</strong> €{averagePrices.eur}
                  </div>
                  <div className="w-1/2 md:w-auto mb-2 md:mb-0">
                    <strong>Average CAD:</strong> ${averagePrices.cad}
                  </div>
                </>
              )}
            {priceFilter.source === "Foil" && (
              <>
                <div className="w-1/2 md:w-auto mb-2 md:mb-0">
                  <strong>Average Foil USD:</strong> ${averagePrices.usd_foil}
                </div>
                <div className="w-1/2 md:w-auto mb-2 md:mb-0">
                  <strong>Average Etched USD:</strong> $
                  {averagePrices.usd_etched}
                </div>
              </>
            )}
            <div className="w-1/2 md:w-auto mb-2 md:mb-0">
              <strong>Average TIX:</strong> {averagePrices.tix} TIX
            </div>
          </div>
        </div>
        {renderPricesTable()}
      </div>
    </div>
  );
};

export default CardDetails;
