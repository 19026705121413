import React, { useCallback, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../components/forms/Button";
import Card from "../components/misc/Card";
import SortableTable from "../components/misc/SortableTable";
import Filter from "../components/misc/Filter";
import { renderMana } from "../functions/renderMana";
import AddByNameQuick from "../components/cards/AddByNameQuick";
import useAxiosInstance from "../services/axiosInstance";
import { ThemeContext } from "../components/layouts/ThemeContext";

const CARDS_PER_PAGE = 50;

const Collection = () => {
    const { theme } = useContext(ThemeContext);
    const axiosInstance = useAxiosInstance();
    const navigate = useNavigate();

    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hoveredImage, setHoveredImage] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [filter, setFilter] = useState({
        name: "",
        set_code: "",
        cmc: "",
        mana_cost: "",
        colors: [],
        card_types: "",
        rarity: "",
        legalities: [],
        subtype: [],
        keywords: [],
        toughness: "",
        power: "",
    });
    const [sets, setSets] = useState([]);

    // Function to clean up colors and ensure they are raw (without braces)
    const cleanColors = (colors) => {
        return colors.map((color) => color.replace(/[{}]/g, '').trim()); // Removes braces
    };

    filter.colors = cleanColors(filter.colors);

    // Utility function to serialize array parameters
    const serializeArrayParams = (key, arr) => {
        const cleanedArr = cleanColors(arr);  // Clean the colors array
        const uniqueArr = [...new Set(cleanedArr)].filter(Boolean);  // Filter out falsy values like empty strings
        return uniqueArr.length ? `${key}=${encodeURIComponent(uniqueArr.join(','))}` : '';  // Join as a single string
    };

    // Fetch collections based on filters and pagination
    const fetchCollections = useCallback(async () => {
        setLoading(true);
        setError(null);

        // Prepare filter parameters without colors and subtype
        let params = {
            page,
            ...Object.fromEntries(Object.entries(filter).filter(([key, v]) => key !== "colors" && key !== "subtype" && v && (Array.isArray(v) ? v.length : true))),
        };

        // Manually serialize array parameters to avoid duplication
        const colorsParam = serializeArrayParams('colors', filter.colors);  // Remove {} from colors and serialize
        const subtypeParam = serializeArrayParams('subtype', filter.subtype);

        // Use URLSearchParams for non-array parameters
        let queryString = new URLSearchParams(params).toString();

        // Manually append colors and subtype parameters
        if (colorsParam) queryString += `&${colorsParam}`;
        if (subtypeParam) queryString += `&${subtypeParam}`;

        console.log(`Full Query String: /api/collection/?${queryString}`);

        try {
            const response = await axiosInstance.get(`/api/collection/?${queryString}`);

            if (response.data && response.data.results) {
                const filteredCollections = response.data.results.filter((card) => {
                    // Clean the colors from the card data (removing braces)
                    const cleanedCardColors = cleanColors(card.colors);

                    // Filter by legalities
                    const legalityMatches = filter.legalities.length === 0 ||
                        filter.legalities.some((selectedLegality) => card.legalities[selectedLegality] === "legal");

                    // Filter by colors (clean both filter.colors and card.colors)
                    const colorMatches = filter.colors.length === 0 ||
                        filter.colors.some((selectedColor) => cleanedCardColors.includes(selectedColor));

                    console.log("Filter Colors: ", filter.colors);
                    console.log("Card Colors (cleaned): ", cleanedCardColors);

                    // Return cards that match both legality and color filters
                    return legalityMatches && colorMatches;
                });

                setCollections((prevCollections) =>
                    page === 1 ? filteredCollections : [...prevCollections, ...filteredCollections]
                );
                setHasMore(response.data.next !== null);
            } else {
                setError("Unexpected API response structure.");
            }
        } catch (error) {
            console.error("Error fetching collections:", error);
            setError("Error fetching collections");
        } finally {
            setLoading(false);
        }
    }, [page, filter, axiosInstance]);

    // Fetch available sets from the Scryfall API
    const fetchSets = useCallback(async () => {
        try {
            const response = await axios.get("https://api.scryfall.com/sets");
            setSets(response.data.data);
        } catch (error) {
            console.error("Error fetching sets:", error);
        }
    }, []);

    // Fetch sets on initial render
    useEffect(() => {
        fetchSets();
    }, [fetchSets]);

    // Reset page and collections when the filter changes
    useEffect(() => {
        setPage(1);
        setCollections([]);
    }, [filter]);

    // Fetch collections when page or filters change
    useEffect(() => {
        fetchCollections();
    }, [fetchCollections]);

    const loadMoreCollections = () => {
        setPage((prevPage) => prevPage + 1);
    };

    // Add a card to the collection
    const handleAddCard = async (card) => {
        try {
            const cardData = {
                card_id: card.id,
                name: card.card_name,
                card_set: card.card_set,
                card_types: card.card_types,
                colors: card.colors,
                rarity: card.rarity,
                image_uris: card.image_uris,
                scryfall_uri: card.scryfall_uri,
                set_name: card.set_name,
                set_code: card.set_code,
                cmc: card.cmc,
                keywords: card.keywords,
                oracle_text: card.oracle_text,
                power: card.power,
                toughness: card.toughness,
                subtype: card.subtype,
                artist: card.artist,
                mana_cost: card.mana_cost,
                legalities: card.legalities,
                price: card.prices.usd,
                quantity: 1,
            };

            await axiosInstance.post("/api/collection/add/", cardData);
            fetchCollections();
        } catch (error) {
            console.error("Error adding card:", error);
            setError("Error adding card to collection");
        }
    };

    // Delete a card from the collection
    const handleDeleteCard = async (cardId) => {
        try {
            await axiosInstance.delete("/api/collection/remove/", { data: { card_id: cardId } });
            fetchCollections();
        } catch (error) {
            console.error("Error deleting card:", error);
            setError("Error removing card from collection");
        }
    };

    // Handle mouse movement for card hover effects
    const handleMouseMove = (e) => {
        setMousePosition({ x: e.clientX, y: e.clientY });
    };

    // Handle hover state for displaying large card images
    const handleMouseEnter = (imageSrc) => {
        setHoveredImage(imageSrc);
    };

    const handleMouseLeave = () => {
        setHoveredImage(null);
    };

    // Render card images based on card data
    const renderImages = (collection) => {
        if (collection.card_faces) {
            return (
                <div className="flex space-x-2">
                    {collection.card_faces.map((face, index) => (
                        <img
                            key={index}
                            src={face.image_uris?.normal}
                            alt={`Face ${index + 1}`}
                            className="card-image transition-transform transform hover:scale-105"
                            style={{ width: "150px", height: "210px" }}
                            onMouseEnter={() => handleMouseEnter(face.image_uris?.large)}
                            onMouseLeave={handleMouseLeave}
                        />
                    ))}
                </div>
            );
        } else if (collection.image_uris) {
            return (
                <img
                    src={collection.image_uris.normal}
                    alt="Card"
                    className="card-image transition-transform transform hover:scale-105"
                    style={{ width: "150px", height: "210px" }}
                    onMouseEnter={() => handleMouseEnter(collection.image_uris?.large)}
                    onMouseLeave={handleMouseLeave}
                />
            );
        }
        return null;
    };

    // Define the columns for the sortable table
    const columns = [
        {
            key: "card_name",
            label: "Card Name",
            render: (value, collection) => (
                <span
                    className="text-blue-500 hover:underline cursor-pointer"
                    onClick={() => navigate(`/dashboard/cards/${collection.card}`)}
                >
                    {value}
                </span>
            ),
            width: 200,
        },
        { key: "set_name", label: "Set Name", width: 150 },
        { key: "card_types", label: "Type", width: 150 },
        { key: "rarity", label: "Rarity", width: 100 },
        {
            key: "mana_cost",
            label: "Mana Cost",
            render: (value) => renderMana(value),
            width: 100,
        },
        { key: "cmc", label: "Converted Mana Cost", width: 100 },
        {
            key: "image_uris",
            label: "Image",
            render: (value, collection) => renderImages(collection),
            width: 150,
            visible: false,  // Default to hidden
        },
        {
            key: "quantity",
            label: "Quantity",
            width: 100,
        },
        {
            key: "keywords",
            label: "Keywords",
            width: 100,
        },
        {
            key: "actions",
            label: "Actions",
            width: 100,
            render: (_, collection) => (
                <div>
                    <button
                        onClick={() => handleDeleteCard(collection.card)}
                        className="text-red-500 mr-2"
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => handleAddCard(collection)}
                        className="text-green-500"
                    >
                        Add
                    </button>
                </div>
            ),
        },
    ];

    // Render the loader when data is being fetched
    if (loading && page === 1) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <p className="text-lg text-gray-700 dark:text-gray-300 ml-4">Loading...</p>
            </div>
        );
    }

    // Render error message if there's an error
    if (error) {
        return <div className="p-6 text-red-500">{error}</div>;
    }

    return (
        <div className="p-6 relative" onMouseMove={handleMouseMove}>
            <h1 className="text-3xl font-bold mb-6 text-green-600 dark:text-green-400">My Collections</h1>
            <Card title="Add Cards" className="w-full max-w-7xl mx-auto mb-6" collapsible>
                <AddByNameQuick onSelectCard={handleAddCard} />
            </Card>
            <Card title="Filter Options" className="w-full mx-auto" collapsible>
                <Filter filter={filter} setFilter={setFilter} sets={sets} />
            </Card>

            <SortableTable
                data={collections}
                columns={columns}
                title="Collections"
                className="w-full sm:w-3/4 max-w-4xl mx-auto mt-6"
                enableColumnSelection={true}
                defaultHiddenColumns={['image_uris']}  // Set default hidden column
            />

            {hasMore && (
                <div className="flex justify-center mt-4">
                    <Button onClick={loadMoreCollections}>Load More</Button>
                </div>
            )}
            {hoveredImage && (
                <img
                    id="large-image"
                    src={hoveredImage}
                    alt="Large Card"
                    className="absolute shadow-lg transition-transform transform hover:scale-105"
                    style={{
                        width: "300px",
                        height: "420px",
                        top: mousePosition.y + 20,
                        left: mousePosition.x + 20,
                        zIndex: 1000,
                    }}
                />
            )}
        </div>
    );
};

export default Collection;
