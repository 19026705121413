import React, { useContext } from 'react';
import Card from '../components/misc/Card';
import BaseLayout from '../components/layouts/BaseLayout';
import { ThemeContext } from "../components/layouts/ThemeContext";
import Button from '../components/forms/Button';

function Features() {
    const { theme } = useContext(ThemeContext);
    const barColor = theme === "dark" ? "bg-[#003268]" : "bg-[#FF6F61]";

    const cardContainerStyle = "w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start";
    const cardImageStyle = "w-full md:w-1/2 h-fill object-cover border-4 border-green-500 rounded-lg shadow-md md:mr-8 mb-8 md:mb-0";
    const cardTextStyle = "text-lg text-left md:w-1/2";
    const cardListStyle = "list-disc list-inside mt-4";

    return (
        <BaseLayout>
            <section className="text-center py-20">
                <Card title="CardRealm Features" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className={cardContainerStyle}>
                        <img className={cardImageStyle} src="/static/features_features_page.png" alt="Features" />
                        <div className={cardTextStyle}>
                            <h2 className="text-2xl mb-4 font-semibold">Discover the Powerful Features of CardRealm</h2>
                            <p>
                                At CardRealm, we offer a suite of powerful tools designed to enhance your Magic: The Gathering experience. Whether you're a casual collector or a competitive player, our platform provides everything you need to manage your collections, build winning decks, and connect with the community.
                            </p>
                        </div>
                    </div>
                </Card>
            </section>

            <section className="text-center py-20">
                <Card title="Collection Management" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className={cardContainerStyle}>
                        <div className={cardTextStyle}>
                            <strong>Organize Your Collection:</strong><br />
                            Effortlessly manage your Magic: The Gathering card collection with intuitive tools that make organization a breeze.<br /><br />

                            <strong>Add, Edit, and Delete Cards with Ease:</strong><br />
                            Quickly add new cards to your collection by simply entering their details or scanning them using our advanced card scanning feature. Editing existing entries is just as simple, allowing you to update card information, such as condition, quantity, or any personal notes. And if you no longer need a card in your collection, deleting it is just a click away.<br /><br />

                            <strong>Bulk Upload Functionality for Quick Updates:</strong><br />
                            Save time by uploading multiple cards at once using our bulk upload feature. Whether you’ve purchased a large set or want to import data from other platforms, this tool allows you to update your collection in minutes. Simply upload a CSV file or use our guided interface to quickly add numerous cards at once.<br /><br />

                            <strong>Card Scanning Using the Camera to Streamline Data Entry:</strong><br />
                            Streamline your data entry process with our integrated card scanning technology. Use your device’s camera to scan cards directly into your collection. The system automatically recognizes the card and fills in the details, minimizing manual input and reducing errors.<br /><br />

                            <strong>Organize Your Collection by Set, Rarity, Color, and Other Attributes:</strong><br />
                            Keep your collection perfectly organized with our advanced sorting and filtering options. Categorize your cards by set, rarity, color, type, and more. Use filters to quickly find specific cards or groups of cards, making it easier to manage and showcase your collection. Whether you’re looking to view all your rare cards or find a specific card from a particular set, our system gives you the flexibility to organize your collection in a way that works best for you.
                        </div>
                        <img className={cardImageStyle} src="/static/collection_managment_features_page.png" alt="Collection Management" />
                    </div>
                </Card>
            </section>

            <section className="text-center py-20">
                <Card title="Comprehensive Cards Database" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className={cardContainerStyle}>
                        <img
                            src="/static/card_database_features_page.png"
                            alt="Cards Database"
                            className={cardImageStyle}
                        />
                        <div className={cardTextStyle}>
                            <strong>Comprehensive Cards Database:</strong><br />
                            Unlock the full potential of your Magic: The Gathering experience with our extensive and detailed cards database. Whether you're looking to discover new cards, evaluate their value, or build your ultimate deck, our database provides everything you need in one place.
                            <div>
                                <ul className={cardListStyle}>
                                    <li>
                                        <strong>Explore Detailed Card Information:</strong><br />
                                        Delve deep into each card’s specifics. From common cards to rare finds, every entry in our database provides all the details you need.
                                        <li><strong>Comprehensive Access:</strong> Our database includes every MTG card ever printed, making it your go-to resource for finding any card.</li>
                                        <li><strong>In-Depth Details:</strong> Find complete information including card name, set, rarity, color, type, abilities, artwork, and current market price.</li>
                                        <li><strong>Search and Filter:</strong> Use our powerful search and filter tools to locate cards quickly by set, color, rarity, type, or specific attributes that matter most to you.</li>
                                    </li>
                                    <li className="mt-4">
                                        <strong>Stay Updated with the Latest Releases:</strong><br />
                                        Our database is regularly updated with the latest card releases, ensuring you have the most current information as soon as new sets and expansions are available.
                                    </li>
                                    <li className="mt-4">
                                        <strong>Track Market Trends:</strong><br />
                                        Analyze historical price data and market trends to make informed decisions about buying, selling, or trading cards. Our database provides real-time and historical insights to keep you ahead of the curve.
                                    </li>
                                    <li className="mt-4">
                                        <strong>Community Contributions:</strong><br />
                                        Benefit from the collective knowledge of the MTG community. See user-contributed insights, reviews, and strategies for each card, and share your own expertise to help others.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Card>
            </section>

            <section className="text-center py-20">
                <Card title="Build and Optimize Your Decks" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Build and Optimize Your Decks:</strong><br />
                                Elevate your Magic: The Gathering gameplay by crafting the perfect deck with our powerful deck-building tools. Whether you’re a beginner experimenting with different strategies or a seasoned player fine-tuning your competitive deck, we provide everything you need to build, optimize, and succeed.
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li>
                                    <strong>Create and Edit Decks with Intuitive Tools:</strong><br />
                                    Our deck-building interface is designed to be user-friendly, allowing you to easily create and modify your decks. Drag and drop cards, organize your deck by card type or mana curve, and see real-time updates to your deck's configuration. Whether you’re starting from scratch or tweaking an existing deck, our tools make the process seamless and enjoyable.
                                </li>
                                <li className="mt-4">
                                    <strong>Import and Export Decks in Various Formats:</strong><br />
                                    Import decks from popular platforms or export your creations to share with others. We support a wide range of formats, ensuring compatibility with other MTG tools and communities. Easily transition between digital and physical play with deck lists that are ready to print or share online.
                                </li>
                                <li className="mt-4">
                                    <strong>Deck Statistics and Performance Analysis:</strong><br />
                                    Gain valuable insights into your deck’s performance with our advanced analytics. View detailed statistics such as mana distribution, card type breakdowns, and potential weaknesses. Use this data to refine your strategy and optimize your deck’s efficiency. Our performance analysis tools help you identify what works and what doesn’t, giving you a competitive edge.
                                </li>
                                <li className="mt-4">
                                    <strong>Card Suggestions Based on Deck Themes:</strong><br />
                                    Enhance your deck-building process with intelligent card suggestions tailored to your deck’s theme or strategy. Whether you’re building an aggro deck, a control deck, or something entirely unique, our system recommends cards that complement your existing choices, helping you to create a synergistic and powerful deck.
                                </li>
                            </ul>
                        </div>
                        <img
                            src="/static/advanced_deck_building_features_page.png"
                            alt="Deck Building"
                            className={cardImageStyle}
                        />
                    </div>
                </Card>
            </section>

            <section className="text-center py-20">
                <Card title="Real-Time Price Tracking" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <img
                            src="/static/wishlist_features_page.png"
                            alt="Price Tracking"
                            className={cardImageStyle}
                        />
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Real-Time Price Tracking:</strong><br />
                                Stay ahead of the game with our comprehensive real-time price tracking tools. Whether you’re buying, selling, or trading Magic: The Gathering cards, having up-to-date price information is crucial. Our platform provides you with all the data you need to make informed decisions in the ever-changing MTG market.
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li>
                                    <strong>Stay Updated with Market Trends:</strong><br />
                                    Access real-time price updates for your favorite cards and sets. Our platform continuously monitors the MTG market, ensuring that you always have the latest price information at your fingertips. This feature is essential for anyone looking to buy or sell cards at the right moment.
                                </li>
                                <li className="mt-4">
                                    <strong>Historical Price Tracking with Interactive Graphs:</strong><br />
                                    Dive deep into the price history of any card with our interactive graphs. Analyze price trends over time, see how different events or set releases have impacted card values, and use this information to predict future price movements. These insights are invaluable for collectors and traders looking to maximize their investments.
                                </li>
                                <li className="mt-4">
                                    <strong>Set Price Alerts for Specific Cards:</strong><br />
                                    Never miss a great deal with our customizable price alerts. Set your desired price thresholds for specific cards, and receive instant notifications when prices meet your criteria. Whether you’re waiting for a card to drop in price before buying or looking to sell at a peak, our price alerts help you make timely decisions with confidence.
                                </li>
                            </ul>
                        </div>
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="Trade and Wishlist Management" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">

                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Trade and Wishlist Management:</strong><br />
                                Elevate your Magic: The Gathering experience by simplifying the management of trades and wishlists. Our platform offers robust tools that allow you to keep track of your desired cards and facilitate fair trades with other users.
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li>
                                    <strong>Create and Manage Wishlists Effortlessly:</strong><br />
                                    Keep your Magic: The Gathering goals organized with personalized wishlists. Add cards that you want to acquire, whether they’re for completing a collection, building a specific deck, or simply adding to your inventory. Our platform allows you to update and manage these lists easily, ensuring you never lose track of the cards you're seeking.
                                </li>
                                <li className="mt-4">
                                    <strong>Track Trades and Transactions:</strong><br />
                                    Stay organized and informed by tracking all your trades and transactions directly through our platform. Whether you’re trading with local players or engaging with the global MTG community, our system records each transaction, giving you a clear history of your exchanges. This feature ensures transparency and helps you manage your trading activities with confidence.
                                </li>
                                <li className="mt-4">
                                    <strong>Evaluate Trades Based on Current Market Prices:</strong><br />
                                    Ensure fair and balanced trades with our real-time market price evaluation tools. Before finalizing any trade, compare the market values of the cards involved to confirm that you’re getting a fair deal. Our platform automatically pulls the latest pricing data, giving you the insights needed to negotiate trades that benefit all parties involved.
                                </li>
                                <li className="mt-4">
                                    <strong>Seamless Integration with Your Collection:</strong><br />
                                    Our trade and wishlist management tools are fully integrated with your collection, allowing you to move cards between your collection and wishlist with ease. This seamless connection ensures that you always know the status of your cards and can quickly update your inventory based on completed trades or newly acquired cards.
                                </li>
                            </ul>
                        </div>
                        <img
                            src="/static/trade.png"
                            alt="Trade and Wishlist Management"
                            className={cardImageStyle}
                        />
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="Community and Social Features" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <img
                            src="/static/community.png"
                            alt="Trade and Wishlist Management"
                            className={cardImageStyle}
                        />
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Connect with Fellow MTG Enthusiasts:</strong><br /><br />
                            </p>
                            <p>
                                Elevate your Magic: The Gathering experience by simplifying the management of trades and wishlists. Our platform offers robust tools that allow you to keep track of your desired cards and facilitate fair trades with other users.
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li>
                                    User profiles showcasing collections and decks
                                </li>
                                <li className="mt-4">
                                    Share your collections and decks with the community
                                </li>
                                <li className="mt-4">
                                    Participate in discussion forums for strategies, trades, and other topics
                                </li>
                                <li className="mt-4">
                                    Follow and interact with other users to build a network
                                </li>
                            </ul>
                        </div>
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="Customizable Notifications and Alerts" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Never Miss Important Updates:</strong><br /><br />
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li>
                                    Customizable notifications for price changes, trade offers, and other events
                                </li>
                                <li className="mt-4">
                                    Receive email and in-app alerts to stay informed
                                </li>
                            </ul>
                        </div>
                        <img
                            src="/static/notifications.png"
                            alt="Notifications and Alerts"
                            className={cardImageStyle}
                        />
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="Advanced Analytics and Insights" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <img
                            src="/static/analytics.png"
                            alt="Analytics and Insights"
                            className={cardImageStyle}
                        />
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Gain Valuable Insights:</strong><br /><br />
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li>
                                    Customizable notifications for price changes, trade offers, and other events
                                </li>
                                <li className="mt-4">
                                    Receive email and in-app alerts to stay informed
                                </li>
                            </ul>
                        </div>
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="Integration with Other MTG Tools" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Seamless Data Synchronization:</strong><br /><br />
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li>
                                    Seamless Data Synchronization:
                                </li>
                                <li className="mt-4">
                                    Sync your data with other popular MTG tools and platforms
                                </li>
                                <li className="mt-4">
                                    Public API for developers to create custom integrations
                                </li>
                                <li className="mt-4">
                                    Import data from platforms like TappedOut and Scryfall
                                </li>
                            </ul>
                        </div>
                        <img
                            src="/static/intergrade.png"
                            alt="Analytics and Insights"
                            className={cardImageStyle}
                        />
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="Security and Privacy" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <img
                            src="/static/secure.png"
                            alt="Security and Privacy"
                            className={cardImageStyle}
                        />
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Protect Your Data:</strong><br /><br />
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li className="mt-4">
                                    Secure authentication methods including two-factor authentication
                                </li>
                                <li className="mt-4">
                                    Data encryption to ensure privacy and security
                                </li>
                                <li className="mt-4">
                                    Privacy settings and activity logs for transparency
                                </li>
                            </ul>
                        </div>

                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="User Experience Enhancements" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Enjoy a Smooth and Intuitive Experience:</strong><br /><br />
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li className="mt-4">
                                    Mobile-friendly design for access on the go
                                </li>
                                <li className="mt-4">
                                    Dark mode for comfortable viewing in low light
                                </li>
                                <li className="mt-4">
                                    User-friendly navigation to find what you need quickly
                                </li>
                            </ul>
                        </div>
                        <img
                            src="/static/user.png"
                            alt="User Experience Enhancements"
                            className={cardImageStyle}
                        />
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title="Gamification and Community Engagement" titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col md:flex-row items-center md:items-start">
                        <img
                            src="/static/gamification.png"
                            alt="Gamification and Community Engagement"
                            className={cardImageStyle}
                        />
                        <div className="text-left md:w-1/2">
                            <p className="text-lg">
                                <strong>Make Your Experience Fun and Engaging:</strong><br /><br />
                            </p>
                            <ul className="list-disc list-inside mt-4">
                                <li className="mt-4">
                                    Achievements and badges for user activity
                                </li>
                                <li className="mt-4">
                                    Exclusive community events and rewards
                                </li>
                                <li className="mt-4">
                                    Step-by-step guided tours for new users
                                </li>
                            </ul>
                        </div>
                    </div>
                </Card>
            </section>
            <section className="text-center py-20">
                <Card title='Join CardRealm Today!' titleSize="text-3xl" titlePosition="center" className="shadow-lg">
                    <div className={`h-1 w-3/4 mx-auto mb-6 ${barColor}`} />
                    <div className="w-3/4 mx-auto flex flex-col items-center">
                        <div className="text-left md:w-full mb-6">
                            <p className="text-lg">
                                Experience the ultimate tool for Magic: The Gathering players. Sign up now and take your MTG game to the next level with CardRealm.
                            </p>
                        </div>
                        <div className="w-full flex justify-center">
                            <Button onClick={() => console.log("Join Now clicked!")}>
                                Join Now
                            </Button>
                        </div>
                    </div>
                </Card>
            </section>
        </BaseLayout>
    );
}

export default Features;
